import React from "react";
import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Box, Container, Stack, Typography } from "@mui/material";

import { Header, Footer, PolicyDialog } from "components/common";
import SideBar from "pages/Profile/SideBar";
import { container, containerWhite } from "./styles";
import { Helmet } from "react-helmet-async";

const ProfileLayout: React.FC = () => {
  const { t } = useTranslation(["profile"]);

  const getHideButtonStyles = () => [container, containerWhite];

  return (
    <Box>
      <Helmet>
        <title>Devportal | Profile</title>
      </Helmet>

      <Header />

      <Box component="main" sx={getHideButtonStyles()}>
        <Container sx={{ py: "40px" }}>
          <Typography variant="h5" paragraph>
            {t("profilePageTitle", { ns: "profile" })}
          </Typography>

          <Stack direction="row" spacing={{ xs: 3, md: 6 }}>
            <SideBar />
            <Outlet />
          </Stack>
        </Container>
      </Box>

      <Footer isAdditionalLinks />
      <PolicyDialog />
    </Box>
  );
};

export default ProfileLayout;
