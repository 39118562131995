import { QueryFunctionContext } from "react-query";

export enum QueryKeys {
  USER = "user",
  APPS = "apps",
  APP = "app",
  PRODUCTS = "products",
  SPEC = "spec",
  ANALYTICS = "analytics",
  CONSENT = "consent",
}

export enum AppRoutes {
  HOME = "/",
  LOGIN = "/login",
  REGISTER = "/register",
  PRODUCTS = "/products",
  DOCUMENTATION = "/docs",
  DOCS_FILENAME = ":docsFile",
  CONTACT_US = "/contact-us",
  PRIVACY = "/privacy-policy",
  TERMS = "/terms-and-conditions",
  DASHBOARD = "/dashboard",
  SWAGGER_PAGE = ":clientId",
  ANALYTICS = "/analytics",
  PROFILE = "/profile",
  ACCOUNT_SETTINGS = "account-settings",
  RESET_PASSWORD = "reset-password",
  LOGIN_QR_CODE = "/login-qrcode",
  ACCOUNT_QR_CODE = "/account-qrcode",
  ACCOUNT_QR_CONFIRM = "/account-confirm",
}

export interface IAxiosBaseContext {
  signUp: (values: IRegisterFormValues) => Promise<undefined>;
  exchange: (code: string) => Promise<TokenExchangeData>;
  refreshToken: (refreshToken: string) => Promise<TokenExchangeData>;
  logOut: (tokens: Pick<SessionData, "id_token" | "refresh_token">) => Promise<unknown>;
  cibaAuth: (values: ICibaAuthRequest) => Promise<CibaAuthData>;
  cibaCallback: (accessToken: string) => Promise<unknown>;
  cibaExchange: (values: ICibaExchangeRequest) => Promise<TokenExchangeData>;
  cibaAccounts: (values: ILoginFormValues) => Promise<{ accounts: ConsentAccount[] }>;
  cibaConfirmAccounts: (values: ICibaAccountsConfirmRequest) => Promise<unknown>;
  cibaDenyAccounts: (values: ICibaAccountsDenyRequest) => Promise<unknown>;
  contactUsRequest: (values: IContactUsFormValues) => Promise<{ sent: boolean }>;
  getCurrentUserData: () => Promise<IUserInfo>;
  updateUser: (values: TUpdateUserFormValues) => Promise<unknown>;
  changePassword: (values: IPasswordResetFormValues) => Promise<unknown>;

  getConsentBasedOnId: (
    context: QueryFunctionContext<[QueryKeys.CONSENT, string | undefined, string | undefined, any]>,
  ) => Promise<IConsent>;

  getApps: (query: string) => Promise<IApp[]>;
  getApp: (context: QueryFunctionContext<[QueryKeys.APP, string | undefined]>) => Promise<IApp>;
  createApp: (body: Omit<IAppRequest, "appId">) => Promise<unknown>;
  updateApp: (body: Omit<IAppRequest, "product">) => Promise<unknown>;
  deleteApp: (id: string) => Promise<{ message: string }>;
  getProducts: () => Promise<IProduct[]>;
  getSwaggerSpec: (context: QueryFunctionContext<[QueryKeys.SPEC, number | undefined]>) => Promise<unknown>;
  getAnalyticsUsage: (
    context: QueryFunctionContext<[QueryKeys.ANALYTICS, string, (IApp | undefined)[]]>,
  ) => Promise<unknown>;
}

export interface IUserInfo {
  id: string;
  accountId: string;
  userName: string;
  email: string;
  organization?: string;
  apps: string[];
  contactNumber?: string;
  contactPerson?: string;
  pwdChangeRequestAttempts: number;
  pwdChangeRequestTimestamp: number;
  pwdChanged: number;
}
export type TUpdateUserFormValues = Required<Pick<IUserInfo, "organization" | "contactPerson" | "contactNumber">>;
export interface IPasswordResetFormValues {
  password: string;
  newPassword: string;
}

export interface TokenExchangeData {
  id_token: string;
  access_token: string;
  refresh_token: string;
}

export interface ICibaAuthRequest {
  clientId: string;
  clientSecret: string;
  consentId: string;
  loginHint: string;
  scope: any;
  consentType: any;
}
export interface CibaAuthData {
  auth_req_id: string;
  access_token: string;
  expires_in: number;
  interval: number;
}
export interface ICibaExchangeRequest {
  authReqId: string;
  clientId: string;
  clientSecret: string;
  loginHint: string;
  scope: any;
  // consentType: any;
}

export interface ICibaAccountsConfirmRequest {
  consentId: string;
  accountIds: string[] | string | any;
  accountTypeWithId: { id: string; type: string }[];
  scope: string;
  loginHint: string;
  consentType: any;
}
export interface ICibaAccountsDenyRequest {
  consentId: string;
  scope: string;
  loginHint: string;
  consentType: any;
}
export interface SessionData extends TokenExchangeData {
  timestamp: number;
}

export type LocaleTypes = "en" | "pt" | "ar";

export interface IContactUsFormValues {
  firstName: string;
  lastName: string;
  email: string;
  message: string;
  captcha?: string;
}

export interface IRegisterFormValues {
  email: string;
  organization: string;
  // contactNumber: string;
  // designation: string;
  password: string;
  // agreeToTerms: boolean;
  captcha?: string;
}

export interface ILoginFormValues {
  email: string;
  password: string;
  consentId: string;
  scope: string;
  consentType: any;
}
export interface IProduct {
  title: string;
  // planId?: number;
  // serviceId?: number;
  scopes?: string;
}

export enum ClientAuthenticatorType {
  CLIENT_SECRET = "client-secret",
  CLIENT_JWT = "client-jwt",
}
export interface IApp {
  analyticsId: string;
  appId: string;
  clientId: string;
  name: string;
  product: IProduct;
  clientAuthenticatorType: ClientAuthenticatorType;
  jwks?: string;
  jwksUrl?: string;
  redirectUris: string[];
  // clientSecret: string;
  // url: string;
}
export interface IAppRequest extends Omit<IApp, "analyticsId"> {
  secret?: string;
}
export interface IConsent {
  data: {
    ConsentId: string;
    Status: string;
  };
}
export type TGranularity = "hour" | "day" | "month";
export interface IUsage {
  clientId: string;
  serviceId: string;
  data: {
    details: {
      name: string;
      granularity: TGranularity;
      since: string;
      until: string;
      metric: string;
      period: number;
    };
    total: number;
    values: number[];
  }[];
}
export enum AnalyticsChartType {
  Usage = "usage",
  StatusCodes = "codes",
}

export enum DialogTypes {
  CREATE,
  UPDATE,
  DELETE,
}

export interface ConsentAccount {
  Servicer: {
    SchemeName: string;
    Identification: string;
  };
  Status: string;
  StatusUpdateDateTime: string;
  Currency: string;
  AccountType: string;
  AccountSubType: string;
  Nickname: string;
  OpeningDate: string;
  Account: [
    {
      SchemeName: string;
      Identification: string;
      Name: string;
    },
  ];
  AccountId: string;
}
