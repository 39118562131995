import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const LocationIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon width="31" height="42" viewBox="0 0 31 42" {...props}>
      <path d="M15.7762 1C7.62842 1 1 7.62842 1 15.7762C1 18.4585 2.20296 21.3433 2.25337 21.465C2.64189 22.3872 3.40852 23.8196 3.96132 24.6593L14.0926 40.01C14.5072 40.6393 15.1208 41 15.7762 41C16.4316 41 17.0452 40.6393 17.4598 40.0109L27.5919 24.6593C28.1456 23.8196 28.9113 22.3872 29.2999 21.465C29.3503 21.3442 30.5524 18.4594 30.5524 15.7762C30.5524 7.62842 23.9239 1 15.7762 1ZM27.6971 20.7905C27.3503 21.6171 26.6367 22.9496 26.1404 23.7023L16.0083 39.0539C15.8083 39.3572 15.7449 39.3572 15.545 39.0539L5.41286 23.7023C4.91656 22.9496 4.20295 21.6163 3.85615 20.7897C3.84137 20.754 2.73837 18.0987 2.73837 15.7762C2.73837 8.58714 8.58714 2.73837 15.7762 2.73837C22.9652 2.73837 28.814 8.58714 28.814 15.7762C28.814 18.1021 27.7084 20.7645 27.6971 20.7905Z" />
      <path d="M15.7758 7.9541C11.462 7.9541 7.95312 11.4639 7.95312 15.7768C7.95312 20.0897 11.462 23.5995 15.7758 23.5995C20.0896 23.5995 23.5985 20.0897 23.5985 15.7768C23.5985 11.4639 20.0896 7.9541 15.7758 7.9541ZM15.7758 21.8611C12.4216 21.8611 9.6915 19.1319 9.6915 15.7768C9.6915 12.4217 12.4216 9.69248 15.7758 9.69248C19.13 9.69248 21.8601 12.4217 21.8601 15.7768C21.8601 19.1319 19.13 21.8611 15.7758 21.8611Z" />
    </SvgIcon>
  );
};

export default LocationIcon;
