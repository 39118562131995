import React from "react";
import { Outlet } from "react-router-dom";

import { Box } from "@mui/material";

import { Header, Footer, PolicyDialog } from "components/common";
import { container, containerPadding } from "./styles";

const DashboardLayout: React.FC = () => {
  const getMainStyles = () => [container, containerPadding];

  return (
    <Box>
      <Header />

      <Box component="main" sx={getMainStyles()}>
        <Outlet />
      </Box>

      <Footer isAdditionalLinks />
      <PolicyDialog />
    </Box>
  );
};

export default DashboardLayout;
