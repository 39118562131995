import { TFunction } from "i18next";

interface IChildren {
  name: string;
  link: string;
  children?: { name: string; link: string }[];
}

export const linksByDifferentClients = (tenantName: string, t: TFunction, environmentName: string): IChildren[] => {
  switch (tenantName) {
    case "kroo":
      return [
        { name: t("sidebar.onboarding"), link: "developer-onboarding" },
        { name: t("sidebar.appManagement"), link: "developer-apps" },
        { name: t("sidebar.aispFlow"), link: "aisp-flow" },
        { name: t("sidebar.pispFlow"), link: "pisp-flow-kroo" },
        { name: t("sidebar.cbpiiFlow"), link: "cbpii-flow" },
        { name: t("sidebar.testingGuides"), link: "testing-postman-flow" },
        { name: "How to generate JWS Signature", link: "jwks" },
      ];
    case "consumerbank-jpmorgan":
      switch (environmentName) {
        case "prod":
          return [
            { name: t("sidebar.onboarding"), link: "jpm-developer-onboarding" },
            { name: t("sidebar.appManagement"), link: "developer-apps-jpm" },
            // { name: t("sidebar.coreBankJpm"), link: "jpm-core-bank" },
            { name: t("sidebar.aispFlow"), link: "aisp-flow-jpm" },
            { name: t("sidebar.pispFlow"), link: "pisp-flow-jpm" },
            { name: t("sidebar.cbpiiFlow"), link: "cbpii-flow-jpm" },
            // { name: t("sidebar.consent"), link: "jpm-consent-module" },
            { name: t("sidebar.postman"), link: "postman-flow-jpm" },
          ];
        case "sandbox":
          return [
            { name: t("sidebar.onboarding"), link: "developer-jpm-onboarding" },
            { name: t("sidebar.appManagement"), link: "developer-jpm-apps" },
            { name: t("sidebar.coreBankJpm"), link: "core-jpm-bank" },
            { name: t("sidebar.aispFlow"), link: "aisp-jpm-flow" },
            { name: t("sidebar.pispFlow"), link: "pisp-jpm-flow" },
            { name: t("sidebar.cbpiiFlow"), link: "cbpii-jpm-flow" },
            { name: t("sidebar.consent"), link: "consent-jpm-module" },
            { name: t("sidebar.postman"), link: "postman-jpm-flow" },
          ];
        default:
          return [
            { name: t("sidebar.onboarding"), link: "developer-onboarding-jpm" },
            { name: t("sidebar.appManagement"), link: "jpm_developer-apps" },
            { name: t("sidebar.coreBankJpm"), link: "core-bank-jpm" },
            { name: t("sidebar.aispFlow"), link: "jpm_aisp-flow" },
            { name: t("sidebar.pispFlow"), link: "jpm_pisp-flow" },
            { name: t("sidebar.cbpiiFlow"), link: "jpm_cbpii-flow" },
            { name: t("sidebar.consent"), link: "consent-module-jpm" },
            { name: t("sidebar.postman"), link: "jpm-postman-flow" },
          ];
      }
    default:
      return [
        { name: t("sidebar.onboarding"), link: "developer-onboarding" },
        { name: t("sidebar.appManagement"), link: "developer-apps" },
        { name: t("sidebar.aispFlow"), link: "aisp-flow" },
        { name: t("sidebar.pispFlow"), link: "pisp-flow" },
        { name: t("sidebar.cbpiiFlow"), link: "cbpii-flow" },
        // { name: "Event Notification Flow", link: "eventnotification-flow" },
        { name: t("sidebar.consent"), link: "consent-module" },
        { name: t("sidebar.admin"), link: "admin-module" },
        { name: t("sidebar.fapiConformance"), link: "fapi-conformance" },
        { name: t("sidebar.postman"), link: "postman-flow" },
        { name: "How to generate JWS Signature", link: "jwks" },
      ];
  }
};

export const accountsAPILinks = (tenantName: string, t: TFunction) => {
  switch (tenantName) {
    case "consumerbank-jpmorgan":
      return [
        { name: t("sidebar.account-access-consent-request"), link: "jpm-aisp#account-access-consent-request" },
        { name: t("sidebar.account-access-consent-status"), link: "jpm-aisp#account-access-consent-status" },
        { name: t("sidebar.remove-account-access-consent"), link: "jpm-aisp#remove-account-access-consent" },
        { name: t("sidebar.accounts-request"), link: "jpm-aisp#accounts-request" },
        { name: t("sidebar.account-details-request"), link: "jpm-aisp#account-details-request" },
        { name: t("sidebar.account-balances-request"), link: "jpm-aisp#account-balances-request" },
        { name: t("sidebar.balances-request"), link: "jpm-aisp#balances-request" },
        { name: t("sidebar.account-beneficiaries-request"), link: "jpm-aisp#account-beneficiaries-request" },
        { name: t("sidebar.beneficiaries-request"), link: "jpm-aisp#beneficiaries-request" },
        { name: t("sidebar.account-direct-debits-request"), link: "jpm-aisp#account-direct-debits-request" },
        { name: t("sidebar.party-request"), link: "jpm-aisp#party-request" },
        { name: t("sidebar.account-party-request"), link: "jpm-aisp#account-party-request" },
        { name: t("sidebar.product-request"), link: "jpm-aisp#product-request" },
        { name: t("sidebar.account-standing-orders-request"), link: "jpm-aisp#account-standing-orders-request" },
        { name: t("sidebar.account-scheduled-payments-request"), link: "jpm-aisp#account-scheduled-payments-request" },
        { name: t("sidebar.account-transactions-request"), link: "jpm-aisp#account-transactions-request" },
        { name: t("sidebar.transactions-request"), link: "jpm-aisp#transactions-request" },
      ];
    default:
      return [
        { name: t("sidebar.account-access-consent-request"), link: "aisp#account-access-consent-request" },
        { name: t("sidebar.account-access-consent-status"), link: "aisp#account-access-consent-status" },
        { name: t("sidebar.remove-account-access-consent"), link: "aisp#remove-account-access-consent" },
        { name: t("sidebar.accounts-request"), link: "aisp#accounts-request" },
        { name: t("sidebar.account-details-request"), link: "aisp#account-details-request" },
        { name: t("sidebar.account-balances-request"), link: "aisp#account-balances-request" },
        { name: t("sidebar.balances-request"), link: "aisp#balances-request" },
        { name: t("sidebar.account-beneficiaries-request"), link: "aisp#account-beneficiaries-request" },
        { name: t("sidebar.beneficiaries-request"), link: "aisp#beneficiaries-request" },
        { name: t("sidebar.account-direct-debits-request"), link: "aisp#account-direct-debits-request" },
        { name: t("sidebar.direct-debits-request"), link: "aisp#direct-debits-request" },
        { name: t("sidebar.party-request"), link: "aisp#party-request" },
        { name: t("sidebar.account-party-request"), link: "aisp#account-party-request" },
        { name: t("sidebar.account-parties-request"), link: "aisp#account-parties-request" },
        { name: t("sidebar.product-request"), link: "aisp#product-request" },
        { name: t("sidebar.account-product-request"), link: "aisp#account-product-request" },
        { name: t("sidebar.account-standing-orders-request"), link: "aisp#account-standing-orders-request" },
        { name: t("sidebar.standing-orders-request"), link: "aisp#standing-orders-request" },
        { name: t("sidebar.account-scheduled-payments-request"), link: "aisp#account-scheduled-payments-request" },
        { name: t("sidebar.scheduled-payments-request"), link: "aisp#scheduled-payments-request" },
        { name: t("sidebar.account-transactions-request"), link: "aisp#account-transactions-request" },
        { name: t("sidebar.transactions-request"), link: "aisp#transactions-request" },
      ];
  }
};

export const paymentsAPILinks = (tenantName: string, t: TFunction) => {
  switch (tenantName) {
    case "consumerbank-jpmorgan":
      return [
        { name: t("sidebar.domestic-payment-consents"), link: "jpm-pisp#domestic-payment-consents" },
        { name: t("sidebar.domestic-payment-consents-status"), link: "jpm-pisp#domestic-payment-consents-status" },
        { name: t("sidebar.funds-confirmation"), link: "jpm-pisp#funds-confirmation" },
        { name: t("sidebar.domestic-payments"), link: "jpm-pisp#domestic-payments" },
        {
          name: t("sidebar.domestic-payments-domesticPaymentId"),
          link: "jpm-pisp#domestic-payments-domesticPaymentId",
        },
        {
          name: t("sidebar.domestic-scheduled-payment-consents"),
          link: "jpm-pisp#domestic-scheduled-payment-consents",
        },
        {
          name: t("sidebar.domestic-scheduled-payment-consents-byid"),
          link: "jpm-pisp#domestic-scheduled-payment-consents-byid",
        },
        { name: t("sidebar.domestic-scheduled-payments"), link: "jpm-pisp#domestic-scheduled-payments" },
        { name: t("sidebar.domestic-scheduled-payments-byid"), link: "jpm-pisp#domestic-scheduled-payments-byid" },
        { name: t("sidebar.domestic-standing-order-consents"), link: "jpm-pisp#domestic-standing-order-consents" },
        {
          name: t("sidebar.domestic-standing-order-consents-byid"),
          link: "jpm-pisp#domestic-standing-order-consents-byid",
        },
        { name: t("sidebar.domestic-standing-order"), link: "jpm-pisp#domestic-standing-order" },
        { name: t("sidebar.domestic-standing-order-byid"), link: "jpm-pisp#domestic-standing-order-byid" },
        { name: "Domestic VRP Consent Request", link: "jpm-pisp#domestic-vrp-consents" },
        { name: "Domestic VRP Consent Status", link: "jpm-pisp#domestic-vrp-consents-byid" },
        { name: "Delete Domestic VRP Consent", link: "jpm-pisp#delete-vrp-consents-byid" },
        { name: "Get Domestic VRP Funds Confirmation", link: "jpm-pisp#domestic-vrp-funds" },
        { name: "Domestic VRP Initiation", link: "jpm-pisp#domestic-vrps" },
        { name: "Get Domestic VRPs by ID", link: "jpm-pisp#domestic-vrp-vrpid" },
      ];
    case "sbi":
      return [
        { name: t("sidebar.domestic-payment-consents"), link: "pisp-sbi#domestic-payment-consents" },
        { name: t("sidebar.domestic-payment-consents-status"), link: "pisp-sbi#domestic-payment-consents-status" },
        { name: t("sidebar.funds-confirmation"), link: "pisp-sbi#funds-confirmation" },
        { name: t("sidebar.domestic-payments"), link: "pisp-sbi#domestic-payments" },
        {
          name: t("sidebar.domestic-payments-domesticPaymentId"),
          link: "pisp-sbi#domestic-payments-domesticPaymentId",
        },
        {
          name: t("sidebar.domestic-scheduled-payment-consents"),
          link: "pisp-sbi#domestic-scheduled-payment-consents",
        },
        {
          name: t("sidebar.domestic-scheduled-payment-consents-byid"),
          link: "pisp-sbi#domestic-scheduled-payment-consents-byid",
        },
        { name: t("sidebar.domestic-scheduled-payments"), link: "pisp-sbi#domestic-scheduled-payments" },
        { name: t("sidebar.domestic-scheduled-payments-byid"), link: "pisp-sbi#domestic-scheduled-payments-byid" },
        { name: t("sidebar.domestic-standing-order-consents"), link: "pisp-sbi#domestic-standing-order-consents" },
        {
          name: t("sidebar.domestic-standing-order-consents-byid"),
          link: "pisp-sbi#domestic-standing-order-consents-byid",
        },
        { name: t("sidebar.domestic-standing-order"), link: "pisp-sbi#domestic-standing-order" },
        { name: t("sidebar.domestic-standing-order-byid"), link: "pisp-sbi#domestic-standing-order-byid" },

        { name: t("sidebar.international-payment-consents"), link: "pisp#international-payment-consents" },
        {
          name: t("sidebar.international-payment-consents-byid"),
          link: "pisp-sbi#international-payment-consents-byid",
        },
        {
          name: t("sidebar.international-payment-consents-funds-confirmation"),
          link: "pisp-sbi#international-payment-consents-funds-confirmation",
        },
        { name: t("sidebar.international-payments"), link: "pisp-sbi#international-payments" },
        {
          name: t("sidebar.international-payments-byid"),
          link: "pisp-sbi#international-payments-byid",
        },
        {
          name: t("sidebar.international-scheduled-payment-consents"),
          link: "pisp-sbi#international-scheduled-payment-consents",
        },
        {
          name: t("sidebar.international-scheduled-payment-consents-byid"),
          link: "pisp-sbi#international-scheduled-payment-consents-byid",
        },
        {
          name: t("sidebar.international-scheduled-payment-consents-byid-funds-confirmation"),
          link: "pisp-sbi#international-scheduled-payment-consents-byid-funds-confirmation",
        },
        { name: t("sidebar.international-scheduled-payments"), link: "pisp-sbi#international-scheduled-payments" },
        {
          name: t("sidebar.international-scheduled-payments-byid"),
          link: "pisp-sbi#international-scheduled-payments-byid",
        },
        {
          name: t("sidebar.international-standing-order-consents"),
          link: "pisp-sbi#international-standing-order-consents",
        },
        {
          name: t("sidebar.international-standing-order-consents-byid"),
          link: "pisp-sbi#international-standing-order-consents-byid",
        },
        { name: t("international-standing-orders"), link: "pisp-sbi#international-standing-orders" },
        { name: t("sidebar.international-standing-orders-byid"), link: "pisp-sbi#international-standing-orders-byid" },
      ];
    default:
      return [
        { name: t("sidebar.domestic-payment-consents"), link: "pisp#domestic-payment-consents" },
        { name: t("sidebar.domestic-payment-consents-status"), link: "pisp#domestic-payment-consents-status" },
        { name: t("sidebar.funds-confirmation"), link: "pisp#funds-confirmation" },
        { name: t("sidebar.domestic-payments"), link: "pisp#domestic-payments" },
        { name: t("sidebar.domestic-payments-domesticPaymentId"), link: "pisp#domestic-payments-domesticPaymentId" },
        { name: t("sidebar.domestic-scheduled-payment-consents"), link: "pisp#domestic-scheduled-payment-consents" },
        {
          name: t("sidebar.domestic-scheduled-payment-consents-byid"),
          link: "pisp#domestic-scheduled-payment-consents-byid",
        },
        { name: t("sidebar.domestic-scheduled-payments"), link: "pisp#domestic-scheduled-payments" },
        { name: t("sidebar.domestic-scheduled-payments-byid"), link: "pisp#domestic-scheduled-payments-byid" },
        { name: t("sidebar.domestic-standing-order-consents"), link: "pisp#domestic-standing-order-consents" },
        {
          name: t("sidebar.domestic-standing-order-consents-byid"),
          link: "pisp#domestic-standing-order-consents-byid",
        },
        { name: t("sidebar.domestic-standing-order"), link: "pisp#domestic-standing-order" },
        { name: t("sidebar.domestic-standing-order-byid"), link: "pisp#domestic-standing-order-byid" },

        { name: "Domestic VRP Consent Request", link: "pisp#domestic-vrp-consents" },
        { name: "Domestic VRP Consent Status", link: "pisp#domestic-vrp-consents-byid" },
        { name: "Delete Domestic VRP Consent", link: "pisp#delete-vrp-consents-byid" },
        { name: "Get Domestic VRP Funds Confirmation", link: "pisp#domestic-vrp-funds" },
        { name: "Domestic VRP Initiation", link: "pisp#domestic-vrps" },
        { name: "Get Domestic VRPs by ID", link: "pisp#domestic-vrp-vrpid" },
        { name: "Get Domestic VRPs by Payment Details", link: "pisp#domestic-vrp-payment-details" },
      ];
  }
};

export const fundsAPILinks = (tenantName: string, t: TFunction) => {
  switch (tenantName) {
    case "consumerbank-jpmorgan":
      return [
        { name: t("sidebar.funds-confirmation-consents"), link: "jpm-cbpii#funds-confirmation-consents" },
        { name: t("sidebar.funds-confirmation-consents-byid"), link: "jpm-cbpii#funds-confirmation-consents-byid" },
        { name: t("sidebar.delete-funds-confirmation-consents"), link: "jpm-cbpii#delete-funds-confirmation-consents" },
        { name: t("sidebar.funds-confirmations"), link: "jpm-cbpii#funds-confirmations" },
      ];
    default:
      return [
        { name: t("sidebar.funds-confirmation-consents"), link: "cbpii#funds-confirmation-consents" },
        { name: t("sidebar.funds-confirmation-consents-byid"), link: "cbpii#funds-confirmation-consents-byid" },
        { name: t("sidebar.delete-funds-confirmation-consents"), link: "cbpii#delete-funds-confirmation-consents" },
        { name: t("sidebar.funds-confirmations"), link: "cbpii#funds-confirmations" },
      ];
  }
};

export const notificationLinks = (tenantName: string, t: TFunction) => {
  switch (tenantName) {
    case "consumerbank-jpmorgan":
      return [
        { name: "Aggregated Polling", link: "jpm-event-notification#aggregated-polling" },
      ];
    default:
      return [
        { name: "Event Subscription", link: "event-notification#event-subscriptions" },
        { name: "Get Event Subscription", link: "event-notification#get-event-subscriptions" },
        { name: "Put Selected Event Subscription", link: "event-notification#put-event-subscriptions" },
        { name: "Delete Event Subscription", link: "event-notification#delete-event-subscriptions" },
        { name: "Aggregated Polling", link: "event-notification#aggregated-polling" },
      ];
  }
};
