import React from "react";
import { Outlet } from "react-router-dom";

import { Box } from "@mui/material";

import { Footer, PolicyDialog } from "components/common";
import RegisterHeader from "components/common/Header/RegisterHeader";
import { container } from "./styles";

const RegisterLayout: React.FC = () => {
  return (
    <Box>
      <RegisterHeader />

      <Box component="main" sx={container}>
        <Outlet />
      </Box>

      <Footer />
      <PolicyDialog />
    </Box>
  );
};

export default RegisterLayout;
