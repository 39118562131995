import { SystemStyleObject } from "@mui/system/styleFunctionSx/styleFunctionSx";
import { Theme } from "@mui/material";

import { HEADER_HEIGHT, FOOTER_HEIGHT } from "constants/globals";

export const container: SystemStyleObject<Theme> = {
  minHeight: `calc(100vh - ${HEADER_HEIGHT + FOOTER_HEIGHT}px)`,
};

export const containerWhite: SystemStyleObject<Theme> = {
  bgcolor: "common.white",
};

export const containerPadding: SystemStyleObject<Theme> = {
  paddingTop: "40px",
};
