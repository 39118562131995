import React, { FC, useState } from "react";
import { NavLink } from "react-router-dom";

import { Collapse, IconButton, List, ListItemButton, Typography, useTheme } from "@mui/material";
import { ExpandMoreRounded, KeyboardArrowRightRounded } from "@mui/icons-material";
import { listItemName, listItemNameWithChildren } from "./styles";

interface SidebarChildrenItemProps {
  name: string;
  link: string;
  children?: { name: string; link: string }[];
}

const SidebarChildrenItem: FC<SidebarChildrenItemProps> = ({ link, name, children }) => {
  const theme = useTheme();

  const [open, setOpen] = useState<boolean>(false);

  const handleOpenCollapse = () => {
    setTimeout(() => {
      setOpen(prev => !prev);
    }, 200);
  };

  return (
    <>
      <ListItemButton
        component={NavLink}
        to={link}
        // @ts-ignore
        style={({ isActive }) => ({
          color: isActive ? theme.palette.text.primary : theme.palette.text.secondary,
          fontWeight: isActive ? 500 : 400,
          padding: "4px 0",
        })}
        onClick={handleOpenCollapse}
      >
        <Typography sx={[!!children?.length ? listItemNameWithChildren : listItemName]}>
          {!!children?.length && (
            <IconButton sx={{ paddingTop: "6px" }}>
              {open ? <ExpandMoreRounded /> : <KeyboardArrowRightRounded />}
            </IconButton>
          )}
          {name}
        </Typography>
      </ListItemButton>

      {!!children?.length && (
        <Collapse in={open} timeout="auto">
          <List disablePadding>
            {children.map(({ link: childLink, name: childName }) => {
              return (
                <ListItemButton
                  key={childLink}
                  component={NavLink}
                  to={childLink}
                  style={{
                    color: theme.palette.text.secondary,
                    fontWeight: 400,
                    padding: "4px 16px",
                  }}
                >
                  <Typography sx={[listItemName, { pl: "72px" }]}>{childName}</Typography>
                </ListItemButton>
              );
            })}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default SidebarChildrenItem;
