import React, { Suspense, lazy, useState, useEffect, useRef, useMemo } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { useTranslation } from "react-i18next";
import deepmerge from "deepmerge";

import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";

import * as locales from "@mui/material/locale";
import { createTheme, responsiveFontSizes, ThemeOptions, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { SnackbarProvider } from "notistack";

import theme from "config/theme";
import queryClient from "config/reactQuery";
import { AxiosInstanceProvider } from "config/axiosConfig";
import PageLoadingSpinner from "components/common/PageLoadingSpinner";
import Layout from "components/layouts";
import RegisterLayout from "components/layouts/RegisterLayout";
import DocumentationLayout from "components/layouts/DocumentationLayout";
import ProfileLayout from "components/layouts/ProfileLayout";
import ProtectedRoute from "components/common/ProtectedRoute";
import DashboardLayout from "components/layouts/DashboardLayout";
import GlobalLayout from "components/layouts/GlobalLayout";
import { getEnvVars } from "requests";
import { setEnvVarsStorage } from "helpers";
import { LOCALES_MAP, isConsumerBank, isAUB } from "constants/globals";
import { AppRoutes, LocaleTypes } from "types";
import JpmFavicon from "assets/images/JpmFavicon.png";
import AUBFavicon from "assets/images/AUBFavicon.png";
import Favicon from "assets/images/favicon.svg";

const Home = lazy(() => import("./pages/Home"));
const Login = lazy(() => import("./pages/Login"));
const QrCodeFlowLogin = lazy(() => import("./pages/QrCodeFlowLogin"));
const QrCodeFlow = lazy(() => import("./pages/QrCodeFlow"));
const QRAccountConfirm = lazy(() => import("./pages/QRAccountConfirm"));
const Register = lazy(() => import("./pages/Register"));
const Products = lazy(() => import("./pages/Products"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const SwaggerTesting = lazy(() => import("./pages/TestingAPIs"));
const Analytics = lazy(() => import("./pages/Analytics"));
const AccountSettings = lazy(() => import("./pages/Profile/AccountSettings"));
const Password = lazy(() => import("./pages/Profile/Password"));
const Documentation = lazy(() => import("./pages/Documentation"));
const DevelopersDetails = lazy(() => import("./pages/Documentation/DevelopersDetails"));
const ContactUs = lazy(() => import("./pages/ContactUs"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const JpmPrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy/JpmPolicy"));
const TermsAndConditions = lazy(() => import("./pages/TermsAndConditions"));
const JpmTermsAndConditions = lazy(() => import("./pages/JpmTermsAndConditions"));
const NotFound = lazy(() => import("./pages/NotFound"));

const App = () => {
  const { i18n } = useTranslation();
  const [direction, setDirection] = useState<ThemeOptions["direction"]>("ltr");
  const [envs, setEnvs] = useState<Record<string, any> | null>(null);
  const [emotionCache, setEmotionCache] = useState(() =>
    createCache({
      key: "css",
      stylisPlugins: [prefixer],
    }),
  );

  useEffect(() => {
    (async () => {
      try {
        const vars = await getEnvVars();
        setEnvVarsStorage(vars);
        setEnvs(vars);
      } catch (e) {
        console.log("error returning env vars");
      }
    })();
  }, []);

  const langRef = useRef<string>();
  useEffect(() => {
    if (langRef.current !== i18n.language) {
      langRef.current = i18n.language;
      setDirection(i18n.dir());
      document.dir = i18n.dir();
      if (i18n.dir() === "rtl") {
        setEmotionCache(
          createCache({
            key: "css-rtl",
            stylisPlugins: [prefixer, rtlPlugin],
          }),
        );
      } else {
        setEmotionCache(
          createCache({
            key: "css",
            stylisPlugins: [prefixer],
          }),
        );
      }
    }
  }, [i18n, i18n.language]);

  const themeWithLocale = useMemo(
    () =>
      responsiveFontSizes(
        createTheme(
          deepmerge(theme(envs), { direction }),
          locales[LOCALES_MAP[i18n.language as LocaleTypes].muiLocale as keyof typeof locales],
        ),
      ),
    [direction, envs, i18n.language],
  );

  return (
    <QueryClientProvider client={queryClient}>
      <AxiosInstanceProvider>
        <HelmetProvider>
          <CacheProvider value={emotionCache}>
            <ThemeProvider theme={themeWithLocale}>
              <SnackbarProvider
                maxSnack={5}
                preventDuplicate
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              >
                <CssBaseline />

                <GlobalLayout>
                  <Suspense fallback={<PageLoadingSpinner />}>
                    {envs && (
                      <Helmet>
                        <link
                          rel="icon"
                          href={
                            isConsumerBank(envs?.tenantName)
                              ? JpmFavicon
                              : isAUB(envs?.tenantName)
                              ? AUBFavicon
                              : Favicon
                          }
                        />
                      </Helmet>
                    )}
                    <Routes>
                      <Route path={AppRoutes.LOGIN_QR_CODE} element={<QrCodeFlowLogin />} />
                      <Route path={AppRoutes.ACCOUNT_QR_CODE} element={<QrCodeFlow />} />
                      <Route path={AppRoutes.ACCOUNT_QR_CONFIRM} element={<QRAccountConfirm />} />
                      <Route path={AppRoutes.LOGIN} element={<Login />} />
                      <Route path={AppRoutes.REGISTER} element={<RegisterLayout />}>
                        <Route index element={<Register />} />
                      </Route>

                      <Route path={AppRoutes.HOME} element={<Layout />}>
                        <Route index element={<Home />} />
                        <Route path={AppRoutes.PRODUCTS} element={<Products />} />
                        <Route path={AppRoutes.CONTACT_US} element={<ContactUs />} />
                        <Route
                          path={AppRoutes.PRIVACY}
                          element={isConsumerBank(envs?.tenantName) ? <JpmPrivacyPolicy /> : <PrivacyPolicy />}
                        />
                        <Route
                          path={AppRoutes.TERMS}
                          element={
                            isConsumerBank(envs?.tenantName) ? <JpmTermsAndConditions /> : <TermsAndConditions />
                          }
                        />
                      </Route>
                      <Route path={AppRoutes.DOCUMENTATION} element={<DocumentationLayout />}>
                        <Route index element={<Documentation />} />
                        <Route path={AppRoutes.DOCS_FILENAME} element={<DevelopersDetails />} />
                      </Route>
                      <Route
                        path={AppRoutes.DASHBOARD}
                        element={
                          <ProtectedRoute>
                            <DashboardLayout />
                          </ProtectedRoute>
                        }
                      >
                        <Route index element={<Dashboard />} />
                        <Route path={AppRoutes.SWAGGER_PAGE} element={<SwaggerTesting />} />
                      </Route>
                      <Route
                        path={AppRoutes.ANALYTICS}
                        element={
                          <ProtectedRoute>
                            <DashboardLayout />
                          </ProtectedRoute>
                        }
                      >
                        <Route index element={<Analytics />} />
                      </Route>

                      <Route
                        path={AppRoutes.PROFILE}
                        element={
                          <ProtectedRoute>
                            <ProfileLayout />
                          </ProtectedRoute>
                        }
                      >
                        <Route index element={<Navigate to={AppRoutes.ACCOUNT_SETTINGS} />} />
                        <Route path={AppRoutes.ACCOUNT_SETTINGS} element={<AccountSettings />} />
                        <Route path={AppRoutes.RESET_PASSWORD} element={<Password />} />
                      </Route>

                      <Route path="*" element={<NotFound />} />
                    </Routes>
                  </Suspense>
                </GlobalLayout>
              </SnackbarProvider>
            </ThemeProvider>
          </CacheProvider>
        </HelmetProvider>
      </AxiosInstanceProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
};

export default App;
