import { LocaleTypes } from "types";

export const HEADER_HEIGHT = 72;
export const FOOTER_HEIGHT = 56;

export const ROWS_IN_TABLE = [25, 50, 100];

export const locales: LocaleTypes[] = ["en", "pt", "ar"];
export const LOCALES_MAP = {
  en: { flag: "GB", text: "English", muiLocale: "enUS" },
  pt: { flag: "PT", text: "Portuguese", muiLocale: "ptPT" },
  ar: { flag: "SA", text: "Arabic", muiLocale: "arEG" },
};

export const publicFolderPath = process.env.PUBLIC_URL;

export const defaultProductData = [
  {
    title: "OBIE AISP",
    scopes: "accounts",
  },
  {
    title: "OBIE PISP",
    scopes: "payments",
  },
  {
    title: "OBIE CBPII",
    scopes: "fundsconfirmations",
  },
  {
    title: "EVENT NOTIFICATION",
    scopes: "events",
  },
];

export const isKroo = (tenantName: string) => tenantName === "kroo";
export const isConsumerBank = (tenantName: string) => tenantName === "consumerbank-jpmorgan";
export const isAUB = (tenantName: string) => tenantName === "aub";
export const isObieBank= (tenantName: string) => tenantName === "obiebank";
export const isSbi= (tenantName: string) => tenantName === "sbi";

export const envNameKroo = (hostUrl: string) =>
  hostUrl?.includes("-sbx") ? "sandbox" : "prod";
export const envName = (hostUrl: string) =>
  hostUrl?.includes("-sandbox") ? "sandbox" : hostUrl?.includes("-e2e") ? "sit" : "prod";
export const envNameAUB = (hostUrl: string) => (hostUrl?.includes("-sandbox") ? "sandbox" : "prod"); // TODO: Changes after environment is ready

export const getEnvironment = (currentEnv: string) => currentEnv === 'PROD' ? 'prod' : currentEnv === 'SBX' ? 'sandbox' : 'sit';