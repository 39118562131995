import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const BankIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon width="42" height="42" viewBox="0 0 42 42" {...props}>
      <path d="M20.9976 6.45264C18.9911 6.45264 17.3594 8.0844 17.3594 10.0908C17.3594 12.0972 18.9911 13.7289 20.9976 13.7289C23.004 13.7289 24.6356 12.0971 24.6356 10.0908C24.6356 8.08448 23.0039 6.45264 20.9976 6.45264ZM20.9976 11.9108C19.9943 11.9108 19.1776 11.094 19.1776 10.0908C19.1776 9.08761 19.9943 8.27087 20.9976 8.27087C22.0008 8.27087 22.8175 9.08761 22.8175 10.0908C22.8175 11.094 22.0008 11.9108 20.9976 11.9108Z" />
      <path d="M40.0909 17.3636C40.5934 17.3636 41 16.9595 41 16.457V12.8211C41 12.5006 40.8314 12.2033 40.5552 12.0399L22.772 1.47904C22.764 1.47367 22.7559 1.46924 22.748 1.46481C21.6427 0.852283 20.3963 0.833703 19.227 1.47725L1.4448 12.0399C1.16866 12.2032 1 12.5006 1 12.8211V16.457C1 16.9595 1.40662 17.3636 1.90912 17.3636H4.63639V33.728C2.63082 33.728 1 35.3586 1 37.364V40.091C1 40.5934 1.40662 41 1.90912 41H40.0909C40.5934 41 41 40.5935 41 40.091V37.364C41 35.3586 39.3692 33.728 37.3636 33.728V17.3636H40.0909ZM39.1818 37.3636V39.1818H2.81815V37.3636C2.81815 36.3613 3.63403 35.5455 4.6363 35.5455H5.54542H36.4545H37.3636C38.3659 35.5454 39.1818 36.3613 39.1818 37.3636ZM6.45454 33.7272V17.3636H10.0909V33.7272H6.45454ZM11.9091 33.7272V17.3636H17.3636V33.7272H11.9091ZM19.1818 33.7272V17.3636H22.8182V33.7272H19.1818ZM24.6363 33.7272V17.3636H30.0909V33.7272H24.6363ZM31.9091 33.7272V17.3636H35.5455V33.7272H31.9091ZM2.81815 15.5455V13.3358L20.1291 3.05166C20.6955 2.74007 21.2974 2.74092 21.8567 3.04543L39.1818 13.3358V15.5455H2.81815Z" />
    </SvgIcon>
  );
};

export default BankIcon;
