import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

import {
  Avatar,
  Box,
  Container,
  Link as MUILink,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
  MenuItem,
  Select,
} from "@mui/material";

import { KeyboardArrowDownRounded } from "@mui/icons-material";

import Logo from "../Logo";
import useHandleLanguage from "hooks/useHandleLanguage";
import { flexContainer, flexContainerWrapper, langSelect, linksWrapper, logoWrapper } from "./styles";
import { LOCALES_MAP } from "constants/globals";
import { AppRoutes, LocaleTypes } from "types";

const footerLists = (t: TFunction) => [
  {
    title: t("nav.navigation"),
    items: [
      { route: AppRoutes.HOME, text: t("nav.home") },
      { route: AppRoutes.PRODUCTS, text: t("nav.ourProducts") },
      { route: AppRoutes.CONTACT_US, text: t("nav.contactUs") },
    ],
  },
  {
    title: t("nav.developers"),
    items: [
      { route: AppRoutes.DOCUMENTATION, text: t("nav.documentation") },
      { route: AppRoutes.DOCUMENTATION + "#faq", text: t("nav.faqs") },
    ],
  },
  {
    title: t("nav.legals"),
    items: [
      { route: AppRoutes.TERMS, text: t("nav.termsConditions") },
      { route: AppRoutes.PRIVACY, text: t("nav.privacyPolicy") },
    ],
  },
];

const FooterWithLinks = () => {
  const { t, i18n } = useTranslation("common");

  const [envLocales, langChange] = useHandleLanguage();

  return (
    <Box sx={flexContainerWrapper}>
      <Container sx={flexContainer}>
        <Stack spacing={1} sx={logoWrapper}>
          <Logo />

          {envLocales.length > 1 && (
            <Select
              sx={langSelect}
              size="small"
              IconComponent={KeyboardArrowDownRounded}
              renderValue={value => (
                <Box sx={{ display: "flex" }}>
                  <Avatar
                    sx={{ width: 24, height: 24, mr: "10px" }}
                    src={`/images/flags/Country=${LOCALES_MAP[value as LocaleTypes].flag}.svg`}
                  />
                  {LOCALES_MAP[value as LocaleTypes].text}
                </Box>
              )}
              value={i18n.language}
              onChange={event => langChange(event.target.value)}
            >
              {envLocales.map(locale => (
                <MenuItem key={locale} value={locale}>
                  {LOCALES_MAP[locale].text}
                </MenuItem>
              ))}
            </Select>
          )}
        </Stack>
        <Stack direction={{ xs: "column", md: "row" }} spacing={2} sx={linksWrapper}>
          {footerLists(t).map(({ title, items }) => {
            return (
              <Box key={title} sx={{ flexBasis: "33%" }}>
                <Typography
                  component="h6"
                  paragraph
                  fontSize="16px"
                  fontWeight="bold"
                  sx={{ mb: { xs: "0", sm: "inherit" } }}
                >
                  {title}
                </Typography>
                <List>
                  {items.map(({ text, route }) => (
                    <ListItem key={text} disableGutters disablePadding>
                      <ListItemText
                        primary={
                          route ? (
                            <MUILink component={Link} to={route} underline="none" color="text.primary">
                              {text}
                            </MUILink>
                          ) : (
                            text
                          )
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            );
          })}
        </Stack>
      </Container>
    </Box>
  );
};

export default FooterWithLinks;
