import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const SupportIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon width="44" height="50" viewBox="0 0 44 50" {...props}>
      <path d="M38.4002 17.4196C38.4002 8.35131 31.0489 1 21.9806 1C12.9123 1 5.561 8.35131 5.561 17.4196C3.21665 17.7115 1 19.244 1 22.8016V31.3763C1 35.39 3.80958 36.8495 6.4732 36.8495H9.11858C9.62235 36.8495 10.0308 36.441 10.0308 35.9373V18.2771C10.0308 17.7733 9.62235 17.3649 9.11858 17.3649H7.3854C7.3854 9.30422 13.92 2.76967 21.9806 2.76967C30.0413 2.76967 36.5758 9.30422 36.5758 17.3649H34.8426C34.3389 17.3649 33.9304 17.7733 33.9304 18.2771V35.9099C33.9304 36.4137 34.3389 36.8221 34.8426 36.8221H36.5758C36.3204 43.0889 33.3101 44.5576 27.2987 44.8221V44.2109C27.2987 43.2033 26.482 42.3865 25.4743 42.3865H19.7366C18.729 42.3865 17.9122 43.2033 17.9122 44.2109V47.1756C17.9122 48.1832 18.729 49 19.7366 49H25.4926C26.5002 49 27.317 48.1832 27.317 47.1756V46.6465C33.1003 46.382 38.0992 45.187 38.4002 36.7674C40.7446 36.4755 42.9612 34.943 42.9612 31.3854V22.8107C42.9612 19.2349 40.7537 17.7115 38.4002 17.4196ZM8.20638 19.1254V34.9977H6.42759C5.07754 34.9977 2.77879 34.5234 2.77879 31.3489V22.7742C2.77879 19.6271 5.05017 19.1254 6.42759 19.1254H8.20638ZM25.4926 45.7982V47.1847H19.7366V44.2201H25.4926V45.7982ZM41.1368 31.3763C41.1368 34.5234 38.8654 35.0251 37.488 35.0251H35.7548V19.1893H37.5336C38.8837 19.1893 41.1824 19.6636 41.1824 22.8381L41.1368 31.3763Z" />
    </SvgIcon>
  );
};

export default SupportIcon;
