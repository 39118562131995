import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const HomeIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon width="16" height="17" viewBox="0 0 16 17" {...props}>
      <path d="M14 5.91324L15.71 7.61324C15.89 7.79324 16 8.04324 16 8.32324C16 8.87324 15.55 9.32324 15 9.32324C14.72 9.32324 14.47 9.21324 14.29 9.02324L8 2.73324L1.71 9.03324C1.53 9.21324 1.28 9.32324 1 9.32324C0.45 9.32324 0 8.87324 0 8.32324C0 8.04324 0.11 7.79324 0.29 7.61324L7.29 0.613242C7.47 0.433242 7.72 0.323242 8 0.323242C8.28 0.323242 8.53 0.433242 8.71 0.613242L12 3.91324V2.32324C12 1.77324 12.45 1.32324 13 1.32324C13.55 1.32324 14 1.77324 14 2.32324V5.91324ZM2 15.3232V10.3232L8 4.32324L14 10.3232V15.3232C14 15.8732 13.55 16.3232 13 16.3232H10V11.3232H6V16.3232H3C2.45 16.3232 2 15.8732 2 15.3232Z" />
    </SvgIcon>
  );
};

export default HomeIcon;
