import { Link } from "react-router-dom";

import { SvgIcon } from "@mui/material";

import { getEnvVarsStorage } from "helpers";
import { isConsumerBank } from "constants/globals";
import { AppRoutes } from "types";
import { handleLogo } from "./config";

const appLogo = {
  height: "48px",
  cursor: "pointer",
};

const consumerAppLogo = {
  height: "24.5px",
  cursor: "pointer",
};

const link = {
  display: "flex",
  alignItems: "center",
};

const Logo = () => {
  const envs = getEnvVarsStorage();
  const isConsumer = isConsumerBank(envs?.tenantName);

  return (
    <Link to={AppRoutes.HOME} style={link}>
      {envs?.logo && <img src={handleLogo(envs?.logo)} alt="logo" style={isConsumer ? consumerAppLogo : appLogo} />}
      {!envs?.logo && (
        <SvgIcon viewBox="0 0 156 48" sx={{ width: "156px", ...appLogo }}>
          <path d="M15.0287 30.2588H18.4053V33.6465V48.0002L0 30.2588H15.0287Z" fill="#8BE4AF" />
          <path d="M18.4043 48.0001V33.6465L32.7044 48.0001H18.4043Z" fill="#3D955C" />
          <path
            d="M4.40448 19.5943L24.0006 0L46.9697 23.2632V48H32.7055L18.4053 33.6463V30.2586H15.0287H0V24L4.40448 19.5943Z"
            fill="#56B87D"
          />
          <path
            d="M77.2316 25.2036V41.015H73.3805V31.1273L69.9566 41.015H66.6234L63.1773 31.1044V41.015H59.3262V25.2036H64.0336L68.3361 36.2174L72.5477 25.2036H77.2316Z"
            fill="#222D27"
          />
          <path
            d="M93.6502 25.0913L88.1322 35.723V41.0157H84.2583V35.723L78.7402 25.0913H83.1551L86.2184 31.578L89.2812 25.0913H93.6502Z"
            fill="#222D27"
          />
          <path
            d="M110.487 34.1905C111.095 34.9116 111.399 35.7599 111.399 36.7353C111.399 38.0268 110.96 39.0634 110.082 39.8437C109.204 40.6247 107.931 41.0149 106.264 41.0149H100.363V25.1362H106.175C107.765 25.1362 108.975 25.4887 109.801 26.195C110.626 26.9007 111.039 27.8693 111.039 29.1003C111.039 30.1221 110.766 30.9475 110.217 31.5778C109.669 32.2088 108.967 32.6144 108.111 32.7947C109.087 33.0039 109.879 33.4701 110.487 34.1905ZM102.413 32.0958H105.836C106.842 32.0958 107.611 31.863 108.145 31.3976C108.678 30.9321 108.944 30.2789 108.944 29.438C108.944 28.6268 108.681 27.9891 108.156 27.5236C107.63 27.0581 106.827 26.8253 105.746 26.8253H102.412V32.0958H102.413ZM108.393 38.5603C108.986 38.0645 109.282 37.3669 109.282 36.4655C109.282 35.5796 108.978 34.8854 108.371 34.3822C107.763 33.8791 106.918 33.6275 105.837 33.6275H102.414V39.3036H105.882C106.962 39.3036 107.799 39.0554 108.393 38.5603Z"
            fill="#222D27"
          />
          <path
            d="M123.268 37.3437H116.286L114.957 41.0152H112.75L118.629 25.4292H120.949L126.805 41.0152H124.597L123.268 37.3437ZM122.682 35.7448L119.777 27.7271L116.871 35.7448H122.682Z"
            fill="#222D27"
          />
          <path
            d="M141.22 41.0149H139.17L130.927 28.4694V41.0149H128.877V25.1362H130.927L139.17 37.7039V25.1362H141.22V41.0149Z"
            fill="#222D27"
          />
          <path
            d="M148.449 32.9972L155.702 41.0156H153.179L146.557 33.6046V41.0156H144.508V25.1362H146.557V32.5014L153.202 25.1362H155.702L148.449 32.9972Z"
            fill="#222D27"
          />
          <path
            d="M63.9231 17.0591C63.0081 17.0591 62.1733 16.8468 61.4186 16.4223C60.664 15.9884 60.065 15.3894 59.6216 14.6253C59.1877 13.8518 58.9707 12.9839 58.9707 12.0217C58.9707 11.0595 59.1877 10.1964 59.6216 9.4323C60.065 8.65877 60.664 8.05976 61.4186 7.63527C62.1733 7.20134 63.0081 6.98438 63.9231 6.98438C64.8476 6.98438 65.6872 7.20134 66.4418 7.63527C67.1965 8.05976 67.7908 8.65406 68.2247 9.41815C68.6586 10.1822 68.8756 11.0501 68.8756 12.0217C68.8756 12.9933 68.6586 13.8612 68.2247 14.6253C67.7908 15.3894 67.1965 15.9884 66.4418 16.4223C65.6872 16.8468 64.8476 17.0591 63.9231 17.0591ZM63.9231 15.9412C64.6118 15.9412 65.2296 15.7809 65.7768 15.4601C66.3333 15.1394 66.7673 14.6819 67.0786 14.0876C67.3993 13.4933 67.5596 12.8047 67.5596 12.0217C67.5596 11.2293 67.3993 10.5407 67.0786 9.95584C66.7673 9.36155 66.338 8.90404 65.7909 8.58331C65.2438 8.26258 64.6212 8.10221 63.9231 8.10221C63.2251 8.10221 62.6025 8.26258 62.0554 8.58331C61.5082 8.90404 61.0743 9.36155 60.7536 9.95584C60.4423 10.5407 60.2866 11.2293 60.2866 12.0217C60.2866 12.8047 60.4423 13.4933 60.7536 14.0876C61.0743 14.6819 61.5082 15.1394 62.0554 15.4601C62.6119 15.7809 63.2345 15.9412 63.9231 15.9412Z"
            fill="#5E6863"
          />
          <path
            d="M77.1693 9.98414C77.1693 10.8048 76.8863 11.4887 76.3203 12.0359C75.7637 12.5736 74.91 12.8424 73.7592 12.8424H71.8631V16.96H70.5754V7.09757H73.7592C74.8723 7.09757 75.7165 7.36642 76.292 7.90411C76.8768 8.44181 77.1693 9.13515 77.1693 9.98414ZM73.7592 11.7812C74.4761 11.7812 75.0043 11.6255 75.3439 11.3142C75.6835 11.0029 75.8533 10.5596 75.8533 9.98414C75.8533 8.76725 75.1553 8.15881 73.7592 8.15881H71.8631V11.7812H73.7592Z"
            fill="#5E6863"
          />
          <path
            d="M80.0573 8.14466V11.4416H83.6513V12.5028H80.0573V15.8988H84.0758V16.96H78.7696V7.08342H84.0758V8.14466H80.0573Z"
            fill="#5E6863"
          />
          <path
            d="M93.7783 16.96H92.4907L87.3118 9.10685V16.96H86.0242V7.08342H87.3118L92.4907 14.9224V7.08342H93.7783V16.96Z"
            fill="#5E6863"
          />
          <path
            d="M100.912 11.8802C101.27 11.9368 101.595 12.083 101.888 12.3189C102.19 12.5547 102.426 12.8471 102.595 13.1962C102.775 13.5452 102.864 13.9178 102.864 14.314C102.864 14.8139 102.737 15.2667 102.482 15.6724C102.228 16.0686 101.855 16.3846 101.364 16.6204C100.883 16.8468 100.313 16.96 99.6522 16.96H95.9733V7.09757H99.5107C100.18 7.09757 100.751 7.21077 101.223 7.43717C101.695 7.65413 102.048 7.95128 102.284 8.32861C102.52 8.70594 102.638 9.13043 102.638 9.60209C102.638 10.187 102.477 10.6728 102.157 11.0595C101.845 11.4369 101.43 11.7104 100.912 11.8802ZM97.2609 11.3567H99.4258C100.03 11.3567 100.497 11.2152 100.827 10.9322C101.157 10.6492 101.322 10.2577 101.322 9.75774C101.322 9.25778 101.157 8.8663 100.827 8.58331C100.497 8.30031 100.02 8.15881 99.3975 8.15881H97.2609V11.3567ZM99.539 15.8988C100.18 15.8988 100.68 15.7478 101.039 15.446C101.397 15.1441 101.577 14.7243 101.577 14.1866C101.577 13.6395 101.388 13.2103 101.011 12.899C100.633 12.5783 100.129 12.4179 99.4966 12.4179H97.2609V15.8988H99.539Z"
            fill="#5E6863"
          />
          <path
            d="M110.481 14.7668H106.179L105.387 16.96H104.029L107.594 7.15417H109.08L112.632 16.96H111.273L110.481 14.7668ZM110.113 13.7197L108.33 8.73895L106.547 13.7197H110.113Z"
            fill="#5E6863"
          />
          <path
            d="M121.94 16.96H120.652L115.473 9.10685V16.96H114.186V7.08342H115.473L120.652 14.9224V7.08342H121.94V16.96Z"
            fill="#5E6863"
          />
          <path
            d="M129.483 16.96L125.422 12.4604V16.96H124.135V7.09757H125.422V11.668L129.498 7.09757H131.125L126.653 12.0359L131.167 16.96H129.483Z"
            fill="#5E6863"
          />
          <path d="M133.893 7.09757V16.96H132.605V7.09757H133.893Z" fill="#5E6863" />
          <path
            d="M143.842 16.96H142.554L137.375 9.10685V16.96H136.087V7.08342H137.375L142.554 14.9224V7.08342H143.842V16.96Z"
            fill="#5E6863"
          />
          <path
            d="M153.508 9.95584C153.234 9.38041 152.838 8.93705 152.319 8.62576C151.8 8.30503 151.197 8.14466 150.508 8.14466C149.819 8.14466 149.197 8.30503 148.64 8.62576C148.093 8.93705 147.659 9.38985 147.338 9.98414C147.027 10.569 146.871 11.2482 146.871 12.0217C146.871 12.7952 147.027 13.4744 147.338 14.0593C147.659 14.6441 148.093 15.0969 148.64 15.4177C149.197 15.729 149.819 15.8846 150.508 15.8846C151.47 15.8846 152.263 15.5969 152.885 15.0215C153.508 14.4461 153.871 13.6678 153.975 12.6868H150.041V11.6397H155.347V12.6302C155.272 13.4414 155.017 14.1866 154.583 14.8658C154.149 15.5356 153.578 16.0686 152.871 16.4648C152.163 16.8515 151.376 17.0449 150.508 17.0449C149.593 17.0449 148.758 16.8327 148.003 16.4082C147.249 15.9742 146.65 15.3752 146.206 14.6111C145.772 13.847 145.555 12.9839 145.555 12.0217C145.555 11.0595 145.772 10.1964 146.206 9.4323C146.65 8.65877 147.249 8.05976 148.003 7.63527C148.758 7.20134 149.593 6.98438 150.508 6.98438C151.555 6.98438 152.479 7.24379 153.281 7.76262C154.093 8.28144 154.682 9.01252 155.05 9.95584H153.508Z"
            fill="#5E6863"
          />
        </SvgIcon>
      )}
    </Link>
  );
};

export default Logo;
