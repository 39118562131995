import { ThemeOptions } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    green?: Palette["primary"];
  }
  interface PaletteOptions {
    green?: PaletteOptions["primary"];
  }
  interface TypeBackground {
    secondary?: TypeBackground["default"];
  }
  interface TypeText {
    tertiary?: TypeText["primary"];
  }
}

const consumerBankTheme = (envs: Record<string, any> | null) =>
  ({
    palette: {
      primary: {
        main: envs?.primaryColor ?? "#275cb2",
      },
      secondary: {
        main: envs?.secondaryColor ?? "#222D27",
      },
      error: {
        main: "#F2545B",
      },
      success: {
        main: "#079453",
      },
      warning: {
        main: "#FFAE1B",
      },
      background: {
        default: "#F8F8F8",
        secondary: "#EEF0EF",
      },
      text: {
        primary: "#000",
        secondary: "#474C50",
        tertiary: "#B1B4BA",
      },
    },
    typography: {
      fontFamily: "'Open Sans', 'Roboto', 'Helvetica', sans-serif",
      h1: { fontWeight: "bold", fontSize: 64 },
      h2: { fontWeight: "bold", fontSize: 48 },
      h3: { fontWeight: "bold", fontSize: 40 },
      h4: { fontWeight: "bold", fontSize: 32 },
      h5: { fontWeight: "bold", fontSize: 24 },
      h6: { fontWeight: "bold", fontSize: 20 },
      subtitle1: {},
      subtitle2: {},
      body1: {},
      body2: {},
      button: {
        textTransform: "none",
      },
      caption: {},
      overline: {},
    },
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: ({ ownerState, theme }) => ({
            backgroundColor: theme.palette.common.white,
            boxShadow: "0px 16px 24px rgba(10, 31, 68, 0.16)",
            color: theme.palette.text.secondary,
            padding: "8px 12px",
          }),
          arrow: ({ ownerState, theme }) => ({
            "&:before": {
              backgroundColor: theme.palette.common.white,
            },
          }),
        },
      },
      MuiPaper: {
        styleOverrides: {
          elevation1: {
            boxShadow: "0px 16px 24px rgba(10, 31, 68, 0.16)",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            boxShadow: "none",
            fontSize: "16px",
            "&:hover": { boxShadow: "none" },
          }),
          endIcon: ({ theme }) => ({
            transform: `scaleX(${theme.direction === "rtl" ? -1 : 1})`,
          }),
          startIcon: ({ theme }) => ({
            transform: `scaleX(${theme.direction === "rtl" ? -1 : 1})`,
          }),
        },
      },
      MuiTextField: {
        defaultProps: { size: "small" },
      },
      MuiAutocomplete: {
        defaultProps: {
          fullWidth: true,
          size: "small",
          autoHighlight: true,
          selectOnFocus: true,
        },
      },
    },
  } as ThemeOptions);

const baseTheme = (envs: Record<string, any> | null) =>
  ({
    palette: {
      primary: {
        main: envs?.primaryColor ?? "#56B87D",
      },
      secondary: {
        main: envs?.secondaryColor ?? "#222D27",
      },
      error: {
        main: "#F2545B",
      },
      success: {
        main: "#079453",
      },
      warning: {
        main: "#FFAE1B",
      },
      background: {
        default: "#F8F8F8",
        secondary: "#EEF0EF",
      },
      text: {
        primary: "#0E1210",
        secondary: "#5E6863",
        tertiary: "#B1B4BA",
      },
    },
    typography: {
      fontFamily: "'Archivo', 'Roboto', 'Helvetica', 'Arial', sans-serif",
      h1: { fontWeight: "bold", fontSize: 64 },
      h2: { fontWeight: "bold", fontSize: 48 },
      h3: { fontWeight: "bold", fontSize: 40 },
      h4: { fontWeight: "bold", fontSize: 32 },
      h5: { fontWeight: "bold", fontSize: 24 },
      h6: { fontWeight: "bold", fontSize: 20 },
      subtitle1: {},
      subtitle2: {},
      body1: {},
      body2: {},
      button: {
        textTransform: "none",
      },
      caption: {},
      overline: {},
    },

    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: ({ ownerState, theme }) => ({
            backgroundColor: theme.palette.common.white,
            boxShadow: "0px 16px 24px rgba(10, 31, 68, 0.16)",
            color: theme.palette.text.secondary,
            padding: "8px 12px",
          }),
          arrow: ({ ownerState, theme }) => ({
            "&:before": {
              backgroundColor: theme.palette.common.white,
            },
          }),
        },
      },
      MuiPaper: {
        styleOverrides: {
          elevation1: {
            boxShadow: "0px 16px 24px rgba(10, 31, 68, 0.16)",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            boxShadow: "none",
            fontSize: "16px",
            "&:hover": { boxShadow: "none" },
          }),
          endIcon: ({ theme }) => ({
            transform: `scaleX(${theme.direction === "rtl" ? -1 : 1})`,
          }),
          startIcon: ({ theme }) => ({
            transform: `scaleX(${theme.direction === "rtl" ? -1 : 1})`,
          }),
        },
      },
      MuiTextField: {
        defaultProps: { size: "small" },
      },
      MuiAutocomplete: {
        defaultProps: {
          fullWidth: true,
          size: "small",
          autoHighlight: true,
          selectOnFocus: true,
        },
      },
    },
  } as ThemeOptions);

const defaultTheme = (envs: Record<string, any> | null) => {
  switch (envs?.tenantName) {
    case "consumerbank-jpmorgan":
      return consumerBankTheme(envs);
    default:
      return baseTheme(envs);
  }
};

// Create a theme instance.
export default defaultTheme;
