import { styled } from "@mui/material";

interface GradientCircleProps
  extends Pick<React.CSSProperties, "height" | "width" | "top" | "right" | "bottom" | "left"> {}

const GradientCircle = styled("div")<GradientCircleProps>(({ theme, height, width, top, right, bottom, left }) => ({
  position: "absolute",
  top: top ?? "inherit",
  right: right ?? "inherit",
  bottom: bottom ?? "inherit",
  left: left ?? "inherit",
  height: height ?? 99,
  width: width ?? 99,
  borderRadius: "50%",
  background: `linear-gradient(100.7deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.dark} 100%)`,
  zIndex: 1,
}));

export default GradientCircle;
