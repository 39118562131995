import React, { useEffect,useLayoutEffect,useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { Box } from "@mui/material";

import DocumentationSidebar from "pages/Documentation/DocumentationSidebar";
import { Header, Footer, FooterWithLinks, PolicyDialog } from "components/common";
import { getEnvVarsStorage } from "helpers";
import { envNameAUB, isKroo, envNameKroo, getEnvironment } from "constants/globals";
import { SetFutureDate } from "./configs";

const DocumentationLayout: React.FC = () => {
  const envs = getEnvVarsStorage();
  const location = useLocation();
  const [,setMytime] = useState(0);
  
  useEffect(() => {
    // TODO: work around for solving delay in getting dom
    const myInterval = setInterval(() => {
      setMytime((prevTime) => prevTime + 1);
    }, 1000);
    return () => clearInterval(myInterval);
  }, []);

  useEffect(() => {
      let consentAnchorElement = document.getElementById("consent-url") as HTMLAnchorElement;
      if (consentAnchorElement) {
        consentAnchorElement.href = envs?.hostUrl?.replace("developer", "consent");
      }

      let corebankAnchorElement = document.getElementById("corebank-url") as HTMLAnchorElement;
      if (corebankAnchorElement) {
        corebankAnchorElement.href = envs?.hostUrl?.replace("developer", "core");
      }

      let adminAnchorElement = document.getElementById("admin-url") as HTMLAnchorElement;
      if (adminAnchorElement) {
        adminAnchorElement.href = envs?.hostUrl?.replace("developer", "admin");
      }
      let postmanAnchorElement = document.getElementById("postman-download-url") as HTMLAnchorElement;
      if (postmanAnchorElement && envNameAUB(envs?.hostUrl) === "prod") {
        postmanAnchorElement.href = postmanAnchorElement.href.replace("SBX", "Prod");
        postmanAnchorElement.innerHTML = postmanAnchorElement.innerHTML.replace("SBX", "Prod");
      }
      if (postmanAnchorElement && isKroo(envs?.tenantName)) {
        postmanAnchorElement.href = postmanAnchorElement.href.replace(
          "OBIE.postman_collection",
          "kroo_postman_collection",
        );
      }
      let postmanEnvElement = document.getElementById("postman-env") as HTMLAnchorElement;
      if (postmanEnvElement && isKroo(envs?.tenantName) && envNameKroo(envs?.hostUrl) === "prod") {
        postmanEnvElement.href = postmanEnvElement.href.replace("sbx", "prod");
      }
      if (postmanEnvElement && envs?.hostUrl.includes("-sbx")) {
        postmanEnvElement.href = postmanEnvElement.href.replace("SIT", "Sandbox");
        postmanEnvElement.innerHTML = postmanEnvElement.innerHTML.replace("SIT", "Sandbox");
      }
      let basePispElement = document.getElementById("base_pisp_url") as HTMLAnchorElement;
      if (basePispElement) {
        basePispElement.href = envs?.basePispUrl;
        basePispElement.innerHTML = basePispElement.href;
      }
      let baseAispElement = document.getElementById("base_aisp_url") as HTMLAnchorElement;
      if (baseAispElement) {
        baseAispElement.href = envs?.baseAispUrl;
        baseAispElement.innerHTML = baseAispElement.href;
      }
      let baseCbpiiElement = document.getElementById("base_cbpii_url") as HTMLAnchorElement;
      if (baseCbpiiElement) {
        baseCbpiiElement.href = envs?.baseCbpiiUrl;
        baseCbpiiElement.innerHTML = baseCbpiiElement.href;
      }
      let wellKnownElement = document.getElementById("well_known_url") as HTMLAnchorElement;
      if (wellKnownElement) {
        wellKnownElement.href = envs?.wellKnownUrl;
        wellKnownElement.innerHTML = wellKnownElement.href;
      }
      // let payloadInfoElement = document.getElementById("payload_info") as HTMLDivElement;
      // if (payloadInfoElement) {
      //   payloadInfoElement.innerHTML = payloadInfoElement.innerHTML.replaceAll("FUTURE_DATE_3M", `${futureDateFn(3)}`);
      //   payloadInfoElement.innerHTML = payloadInfoElement.innerHTML.replaceAll("FUTURE_DATE_4M", `${futureDateFn(4)}`);
      //   payloadInfoElement.innerHTML = payloadInfoElement.innerHTML.replaceAll("FUTURE_DATE_6M", `${futureDateFn(6)}`);
      //   payloadInfoElement.innerHTML = payloadInfoElement.innerHTML.replaceAll("SELF_LINK", `${envs?.authHost}`);
      // }
      for (let i = 0; i < 3; i++) {
        let fapiJsonElement = document.getElementById(`fapi-json${i}`) as HTMLAnchorElement;
        if (fapiJsonElement) {
          fapiJsonElement.href = fapiJsonElement?.href?.replace("sit", getEnvironment(envs?.currentEnv));
        }
      }
      let jwksAnchorElement: HTMLCollectionOf<Element> = document.getElementsByClassName("jwks-url");
      if (jwksAnchorElement?.length) {
        for (let i in jwksAnchorElement) {
          if (jwksAnchorElement[i]?.innerHTML) {
          jwksAnchorElement[i].innerHTML = `<a href='${envs?.hostUrl}/docs/jwks'>here</a>`;
          }
        }
      }
      let payloadInfoElement: HTMLCollectionOf<Element> = document.getElementsByClassName("language-json");
      if (payloadInfoElement?.length) {
        for (let i in payloadInfoElement) {
          if (payloadInfoElement[i]?.innerHTML) {
            payloadInfoElement[i].innerHTML = SetFutureDate(payloadInfoElement[i].innerHTML, envs);
          }
        }
      }
      let mockbankInfo = document.getElementById("mockbank_info") as HTMLDivElement;
      if (isKroo(envs?.tenantName) && mockbankInfo) {
        mockbankInfo.style.display = "none";
      }
  }, [location.pathname, envs?.hostUrl, envs?.baseAispUrl, envs?.basePispUrl, envs?.baseCbpiiUrl, envs?.wellKnownUrl, envs?.tenantName, envs?.authHost, envs]);
  return (
    <Box>
      <Header />

      <Box component="main" style={{ display: "flex",  height: "100%"  }}>
        <DocumentationSidebar />
        <Box sx={{flex:1, overflow:"scroll"}}> <Outlet /></Box>
      </Box>

      <FooterWithLinks />
      <Footer />
      <PolicyDialog />
    </Box>
  );
};

export default DocumentationLayout;
