import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { getEnvVarsStorage } from "helpers";
import { locales } from "constants/globals";
import { LocaleTypes } from "types";

const useHandleLanguage = (): [LocaleTypes[], (locale: string) => Promise<void>] => {
  const { i18n } = useTranslation();

  const envLocales = useMemo(() => {
    const env = getEnvVarsStorage()?.appI18n;
    if (env) return locales.filter(l => env.includes(l));
    return locales;
  }, []);

  const langChange = async (locale: string) => {
    localStorage.setItem("lang", locale);
    await i18n.changeLanguage(locale);
  };

  return [envLocales, langChange];
};

export default useHandleLanguage;
