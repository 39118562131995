import React from "react";

import { Stack, Theme, Typography } from "@mui/material";
import { CheckRounded } from "@mui/icons-material";
import { SystemStyleObject } from "@mui/system/styleFunctionSx/styleFunctionSx";
import { useTranslation } from "react-i18next";

const iconSize: SystemStyleObject<Theme> = {
  fontSize: "17px",
};

interface PasswordCheckerProps {
  password: string;
  error: boolean;
}

const PasswordChecker: React.FC<PasswordCheckerProps> = ({ password, error }) => {
  const { t } = useTranslation("common");

  const eightCharColor = password.length > 7 ? "primary" : error ? "error" : "text.secondary";
  const upperCaseColor = !!password.match(/[A-Z]/) ? "primary" : error ? "error" : "text.secondary";
  const numberColor = !!password.match(/\d/) ? "primary" : error ? "error" : "text.secondary";
  const specialCharColor = !!password.match(/[!@#$%^&*]/) ? "primary" : error ? "error" : "text.secondary";

  return (
    <Stack direction={{ xs: "column", sm: "row" }} justifyContent="space-between" sx={{ marginBottom: "15px" }}>
      <div>
        <Typography color={eightCharColor}>
          <CheckRounded sx={iconSize} /> {t("passwordChecker.eightChar")}
        </Typography>
        <Typography color={upperCaseColor}>
          <CheckRounded sx={iconSize} /> {t("passwordChecker.upperCase")}
        </Typography>
      </div>
      <div>
        <Typography color={numberColor}>
          <CheckRounded sx={iconSize} /> {t("passwordChecker.number")}
        </Typography>
        <Typography color={specialCharColor}>
          <CheckRounded sx={iconSize} /> {t("passwordChecker.specialChar")}
        </Typography>
      </div>
    </Stack>
  );
};

export default PasswordChecker;
