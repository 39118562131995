import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "config/i18n";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

// Redirect from "/" to "/developer"
// if (!window.location.pathname.includes("developer")) {
//   window.history.replaceState("", "", "developer" + window.location.pathname);
// }

root.render(
  <React.StrictMode>
  <BrowserRouter /*basename="developer"*/>
    <App />
  </BrowserRouter>,
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
