import React, { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

import PageLoadingSpinner from "components/common/PageLoadingSpinner";
import { useGetUserDataQuery } from "hooks/reactQueryHooks/userHooks";

const GlobalLayout: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const { isFetching } = useGetUserDataQuery();

  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  if (isFetching) {
    return <PageLoadingSpinner />;
  }

  return children;
};

export default GlobalLayout;
