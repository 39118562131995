import { SystemStyleObject } from "@mui/system/styleFunctionSx/styleFunctionSx";
import { Theme } from "@mui/material";

export const listWrapper: SystemStyleObject<Theme> = {
  bgcolor: "background.secondary",
  minWidth: "300px",
  maxWidth: "324px",
  overflow: "auto",
  display: { xs: "none", md: "block" },
};
export const listItemName: SystemStyleObject<Theme> = {
  pr: "8px",
  pl: "56px",
  fontSize: "14px",
  color: "inherit",
  fontWeight: "inherit",
};
export const listItemNameWithChildren: SystemStyleObject<Theme> = {
  pr: "8px",
  pl: "48px",
  fontSize: "14px",
  color: "inherit",
  fontWeight: "inherit",
};

export const defaultMargin: SystemStyleObject<Theme> = {
  marginTop: "64px",
};

export const userGuidePaper: SystemStyleObject<Theme> = {
  bgcolor: "background.secondary",
  padding: { xs: "15px", md: "24px" },
};

export const standardsPaper: SystemStyleObject<Theme> = {
  padding: { xs: "15px", md: "24px" },
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
};

export const bannerSection: SystemStyleObject<Theme> = {
  bgcolor: "secondary.main",
};

export const bannerContainer: SystemStyleObject<Theme> = {
  display: "flex",
  flexDirection: { xs: "column", md: "row" },
  justifyContent: "space-between",
  alignItems: "center",
};
export const bannerBlock: SystemStyleObject<Theme> = {
  maxWidth: "480px",
  py: "24px",
};

export const mdContainer: SystemStyleObject<Theme> = {
  py: "30px",
  "& pre": {
    backgroundColor: theme => theme.palette.grey[200],
    fontSize: "14px",
    p: "16px",
    overflow: "auto",
  },
  "& td": {
    padding: "16px",
    margin: "0",
    borderBottom: theme => "1px solid " + theme.palette.grey[200],
  },
  "& table": {
    borderSpacing: "0",
    backgroundColor: theme => theme.palette.common.white,
    display: { xs: "block", sm: "inherit" },
    overflow: { xs: "scroll", sm: "auto" },
  },
  "& th": {
    backgroundColor: theme => theme.palette.grey[200],
    padding: "16px",
  },
  "& img": {
    width: "100%",
    maxWidth: "600px",
    display: "block",
    margin: "auto",
  },
};
