import { styled } from "@mui/material";

interface OutlinedCircleProps extends Pick<React.CSSProperties, "top" | "right" | "bottom" | "left"> {
  size?: number;
}

const OutlinedCircle = styled("div")<OutlinedCircleProps>(({ theme, top, right, bottom, left, size }) => ({
  position: "absolute",
  top: top ?? "inherit",
  right: right ?? "inherit",
  bottom: bottom ?? "inherit",
  left: left ?? "inherit",
  height: size ?? 64,
  width: !!size ? size / 2 : 64 / 2,
  borderRadius: `${size}px 0% 0% ${size}px`,
  border: `4px solid ${theme.palette.primary.main}`,
  borderRight: 0,

  "&:after": {
    content: "''",
    position: "absolute",
    height: `calc(${size ?? 64}px/2.5)`,
    width: `calc(${!!size ? size / 2 : 64 / 2}px/2.5)`,
    top: "50%",
    right: 0,
    transform: "translateY(-50%)",
    borderRadius: `${size}px 0% 0% ${size}px`,
    border: `4px solid ${theme.palette.primary.main}`,
    borderRight: 0,
  },
}));

export default OutlinedCircle;
