import { Chip, ChipProps, lighten, styled } from "@mui/material";

const ActivityLabel = styled(Chip)<ChipProps>(({ theme }) => ({
  borderRadius: "4px",
  fontWeight: 700,
  height: 26,
  "&.MuiChip-colorSuccess": {
    backgroundColor: lighten(theme.palette.success.main, 0.8),
    color: theme.palette.success.main,
  },
  "&.MuiChip-colorError": {
    backgroundColor: lighten(theme.palette.error.main, 0.8),
    color: theme.palette.error.main,
  },
  "&.MuiChip-colorInfo": {
    backgroundColor: lighten(theme.palette.info.main, 0.8),
    color: theme.palette.info.main,
  },
}));

export default ActivityLabel;
