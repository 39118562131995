import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const StandardIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon width="16" height="15" viewBox="0 0 16 15" {...props}>
      <path d="M0 13.3232C0 13.8732 0.45 14.3232 1 14.3232H15C15.55 14.3232 16 13.8732 16 13.3232V1.32324C16 0.773242 15.55 0.323242 15 0.323242H1C0.45 0.323242 0 0.773242 0 1.32324V13.3232ZM2 4.32324H14V12.3232H2V4.32324ZM4 5.32324C4.28 5.32324 4.53 5.43324 4.71 5.61324L6.71 7.61324C6.89 7.79324 7 8.04324 7 8.32324C7 8.60324 6.89 8.85324 6.71 9.03324L4.71 11.0332C4.53 11.2132 4.28 11.3232 4 11.3232C3.45 11.3232 3 10.8732 3 10.3232C3 10.0432 3.11 9.79324 3.29 9.61324L4.59 8.32324L3.29 7.03324C3.11 6.85324 3 6.60324 3 6.32324C3 5.77324 3.45 5.32324 4 5.32324ZM12 9.32324H9C8.45 9.32324 8 9.77324 8 10.3232C8 10.8732 8.45 11.3232 9 11.3232H12C12.55 11.3232 13 10.8732 13 10.3232C13 9.77324 12.55 9.32324 12 9.32324Z" />
    </SvgIcon>
  );
};

export default StandardIcon;
