import { futureDateFn } from "helpers";

export const SetFutureDate = (text = "", envs: Record<string, any> | null) => {
  text = text?.replaceAll("FUTURE_DATE_6M", `${futureDateFn(6)}`);
  text = text?.replaceAll("FUTURE_DATE_4M", `${futureDateFn(4)}`);
  text = text?.replaceAll("FUTURE_DATE_3M", `${futureDateFn(3)}`);
  text = text?.replaceAll("SELF_LINK", `${envs?.hostUrl?.replace("developer", "api")}`);

  return text;
};
