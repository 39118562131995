import { setLogger, QueryClient } from "react-query";

setLogger({
  log: window.console.log,
  warn: window.console.warn,
  error: () => {},
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      cacheTime: Infinity,
    },
    mutations: {
      // mutation options
    },
  },
});

// queryClient.setQueryDefaults('dfgdfgdg', { queryFn: fetchSomething });
// queryClient.setMutationDefaults('dfgdfgdfgd', { mutationFn: signup });

export default queryClient;
