import React, { PropsWithChildren } from "react";

import { AppBar, Toolbar, Container } from "@mui/material";

import Logo from "../Logo";
import { appBar, toolBar } from "./styles";

const BaseHeader: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <AppBar position="static" sx={appBar}>
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={toolBar}>
          <Logo />
          {children}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default BaseHeader;
