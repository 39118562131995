import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const EndToEndIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon width="51" height="48" viewBox="0 0 51 48" {...props}>
      <path d="M12.0991 24.5311H36.9836C42.4767 24.5311 46.9352 20.222 46.9352 14.9314C46.9352 9.64091 42.4661 5.33175 36.9836 5.33175H12.7071C12.0991 1.74787 8.62189 -0.630756 5.03801 0.147919C1.82741 0.841196 -0.348518 3.9131 0.0461696 7.16639C0.494155 10.8143 3.88604 13.3102 7.46993 12.7129C10.1578 12.2542 12.2591 10.153 12.7178 7.46508H36.9943C41.3034 7.46508 44.8127 10.8143 44.8127 14.9315C44.8127 19.0487 41.3035 22.398 36.9943 22.398H12.0991C6.60596 22.398 2.1367 26.7071 2.1367 31.9977C2.1367 37.2882 6.60585 41.5974 12.0884 41.5974H29.4211L24.856 46.1732C24.4293 46.5785 24.4187 47.2612 24.8347 47.6772C25.2506 48.0931 25.9226 48.1144 26.3386 47.6985C26.3493 47.6878 26.3599 47.6772 26.3599 47.6772L32.749 41.2881C33.165 40.8721 33.165 40.2001 32.749 39.7841L26.3599 33.3843C25.9332 32.979 25.2612 32.9896 24.856 33.4056C24.4613 33.8216 24.4613 34.4723 24.856 34.8883L29.4318 39.4641H12.0991C7.77922 39.4641 4.27003 36.1149 4.27003 31.9977C4.27003 27.8804 7.77922 24.5311 12.0991 24.5311ZM6.40326 10.6543C4.04604 10.6543 2.1367 8.74504 2.1367 6.38771C2.1367 4.03049 4.04594 2.12115 6.40326 2.12115C8.76048 2.12115 10.6698 4.03039 10.6698 6.38771C10.6698 8.74494 8.76048 10.6543 6.40326 10.6543Z" />
      <path d="M43.7357 34.1201C40.2051 34.1201 37.3359 36.9893 37.3359 40.5199C37.3359 44.0505 40.2051 46.9197 43.7357 46.9197C47.2663 46.9197 50.1355 44.0505 50.1355 40.5199C50.1355 36.9893 47.277 34.1308 43.7357 34.1201ZM43.7357 44.7865C41.3785 44.7865 39.4692 42.8772 39.4692 40.5199C39.4692 38.1626 41.3784 36.2533 43.7357 36.2533C46.093 36.2533 48.0023 38.1626 48.0023 40.5199C48.0023 42.8772 46.093 44.7865 43.7357 44.7865Z" />
    </SvgIcon>
  );
};

export default EndToEndIcon;
