import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const AccountsIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon width="72" height="66" viewBox="0 0 72 66" {...props}>
      <path d="M33.5753 45.1272V51.5165H9.84551V45.1269C9.84551 44.3129 10.3305 43.5855 11.0819 43.2724L16.4731 41.0261C17.7635 40.4885 18.5996 39.2398 18.6061 37.8429C18.978 37.7889 19.3234 37.5723 19.5271 37.2207L19.5271 37.2206C19.8802 36.6106 19.6721 35.8299 19.0623 35.4767L19.0622 35.4766C17.4302 34.5317 16.4161 32.7741 16.4161 30.8911V26.5108C16.4161 24.2441 18.7373 22.3115 21.7104 22.3115C24.6836 22.3115 27.0047 24.244 27.0047 26.5108V30.8911C27.0047 32.7749 25.99 34.5329 24.3569 35.4778C23.7468 35.8308 23.5384 36.6114 23.8913 37.2216L24.1077 37.0964L23.8913 37.2216C24.0951 37.5739 24.4415 37.7921 24.8147 37.8459C24.8223 39.2417 25.6581 40.4891 26.9479 41.0264L32.339 43.2728L32.4352 43.042L32.3391 43.2728C33.0903 43.5857 33.5753 44.3133 33.5753 45.1272ZM36.7107 51.5163H36.1274V45.1269C36.1274 43.2799 35.0254 41.6267 33.3204 40.9165L27.9292 38.6701L27.8331 38.9009L27.9292 38.6701C27.5873 38.5276 27.3668 38.1969 27.3668 37.8265V36.3256C28.7495 34.882 29.5569 32.9378 29.5569 30.891V26.5107C29.5569 22.7525 25.9992 19.759 21.7103 19.759C17.4214 19.759 13.8636 22.7524 13.8636 26.5107V30.891C13.8636 32.9376 14.671 34.8819 16.0538 36.3256V37.8265C16.0538 38.1969 15.8333 38.5276 15.4913 38.6702C15.4913 38.6702 15.4913 38.6702 15.4913 38.6702L10.1003 40.9165C8.39534 41.6268 7.29314 43.28 7.29314 45.1271V51.5164H5.28415C4.17656 51.5164 3.27503 50.6148 3.27503 49.5074V14.6458H59.857V27.4384C59.857 28.1432 60.4283 28.7146 61.1332 28.7146C61.838 28.7146 62.4093 28.1432 62.4093 27.4384V5.70407C62.4093 3.18868 60.3634 1.14258 57.848 1.14258H5.28415C2.76875 1.14258 0.722656 3.18867 0.722656 5.70407V49.5073C0.722656 52.0227 2.76876 54.0686 5.28415 54.0686H36.7107C37.4155 54.0686 37.9869 53.4972 37.9869 52.7924C37.9869 52.0876 37.4155 51.5163 36.7107 51.5163ZM5.28415 3.69496H57.848C58.9555 3.69496 59.857 4.59647 59.857 5.70407V12.0934H3.27503V5.70407C3.27503 4.59649 4.17657 3.69496 5.28415 3.69496Z" />
      <path d="M40.1524 38.1454L54.8145 33.0392C54.8145 33.0392 54.8145 33.0392 54.8145 33.0392C55.0862 32.9446 55.3817 32.9446 55.6533 33.0392L70.4366 38.1813C70.959 38.3629 71.305 38.8605 71.2929 39.4136L40.1524 38.1454ZM40.1524 38.1454C39.5807 38.2819 39.1613 38.8021 39.1741 39.4136L39.1741 39.4138C39.1781 39.5959 39.2845 43.5536 41.2269 48.6771L41.4607 48.5885L41.2269 48.6771C43.0195 53.4051 46.7882 60.0584 54.5591 64.8952C54.7655 65.0236 54.9995 65.088 55.2335 65.088C55.4673 65.088 55.7013 65.0239 55.9078 64.8952C63.6788 60.0584 67.4475 53.4051 69.2401 48.6771C71.182 43.5552 71.289 39.5984 71.2929 39.4137C71.2929 39.4138 71.2929 39.4138 71.2929 39.4139L40.1524 38.1454ZM41.7951 40.2761L55.2338 35.5958L68.6743 40.2708C68.5353 41.6476 68.1141 44.4484 66.8538 47.7724L67.0876 47.861L66.8538 47.7724C65.2526 51.9956 61.9449 57.879 55.2338 62.2966C48.5517 57.8979 45.2465 52.0463 43.6421 47.8468C42.7376 45.4792 42.2585 43.3622 42.0056 41.8335C41.9021 41.2078 41.8365 40.6809 41.7951 40.2761Z" />
      <path d="M59.2315 45.002C59.8016 44.4323 60.7257 44.4323 61.2958 45.002C61.8656 45.5721 61.8656 46.4962 61.2958 47.0663L55.4579 52.9041C54.8878 53.4739 53.9637 53.4739 53.3936 52.9041L49.0152 48.5258C48.4454 47.9557 48.4454 47.0316 49.0152 46.4615C49.5853 45.8917 50.5094 45.8917 51.0795 46.4615L54.4258 49.808L59.2315 45.002Z" />
      <path d="M37 23.582C37 22.7084 37.7083 22 38.582 22H51.2377C52.1114 22 52.8197 22.7084 52.8197 23.582C52.8197 24.4555 52.1114 25.1639 51.2377 25.1639H38.582C37.7083 25.1639 37 24.4555 37 23.582Z" />
    </SvgIcon>
  );
};

export default AccountsIcon;
