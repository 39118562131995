import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

import { List, ListItemButton, ListItemIcon, Typography } from "@mui/material";

import { HomeIcon, UserGuideIcon, StandardIcon, FaqIcon, ProductsIcon } from "components/icons";
import SidebarChildrenItem from "./SidebarChildrenItem";
import { getEnvironment } from "constants/globals";
import { isConsumerBank, isKroo, isSbi, isObieBank } from "constants/globals";
import { getEnvVarsStorage } from "helpers";
import { accountsAPILinks, fundsAPILinks, linksByDifferentClients, paymentsAPILinks, notificationLinks } from "./sidebarLinks";
import { listWrapper } from "./styles";
import { AppRoutes } from "types";

const getDocumentationMenu = (
  t: TFunction,
  pispWithoutPayments: boolean,
  tenantName: string,
  isCoreBankNeeded = false,
  environmentName: string,
) => [
  { link: "/", icon: <HomeIcon sx={{ fontSize: "16px" }} />, name: t("sidebar.home"), children: [] },
  {
    link: `${AppRoutes.DOCUMENTATION}#user-guide`,
    icon: <UserGuideIcon sx={{ fontSize: "16px" }} />,
    name: t("sidebar.guides"),
    children: [
      // { name: t("sidebar.onboarding"), link: "developer-onboarding" },
      // { name: t("sidebar.appManagement"), link: "developer-apps" },
      // { name: t("sidebar.aispFlow"), link: "aisp-flow" },
      // { name: t("sidebar.pispFlow"), link: "pisp-flow" },
      // { name: t("sidebar.cbpiiFlow"), link: "cbpii-flow" },
      ...linksByDifferentClients(tenantName, t, environmentName),
      ...(isCoreBankNeeded ? [{ name: t("sidebar.coreBank"), link: "core-bank" }] : []),
    ],
  },
  {
    link: `${AppRoutes.DOCUMENTATION}#standards`,
    icon: <StandardIcon sx={{ fontSize: "16px" }} />,
    name: t("sidebar.standards"),
    children: [],
  },
  {
    link: `${AppRoutes.DOCUMENTATION}#faq`,
    icon: <FaqIcon sx={{ fontSize: "16px" }} />,
    name: t("sidebar.faq"),
    children: [],
  },
  {
    link: `${AppRoutes.DOCUMENTATION}#products`,
    icon: <ProductsIcon sx={{ fontSize: "16px" }} />,
    name: t("sidebar.products"),
    children: [
      {
        name: t("sidebar.accountsAPI"),
        link: isConsumerBank(tenantName) ? "jpm-aisp" : "aisp",
        children: accountsAPILinks(tenantName, t),
      },
      {
        name: t("sidebar.paymentsAPI"),
        link: isConsumerBank(tenantName) ? "jpm-pisp" : isKroo(tenantName) ? "pisp_kroo" : isSbi(tenantName) ? "pisp-sbi" : "pisp",
        children: paymentsAPILinks(tenantName, t),
      },
      {
        name: t("sidebar.fundsAPI"),
        link: isConsumerBank(tenantName) ? "jpm-cbpii" : "cbpii",
        children: fundsAPILinks(tenantName, t),
      },
      ...(isConsumerBank(tenantName)
        ? [
            {
              name: "Event Notification API",
              link: isConsumerBank(tenantName)? "jpm-event-notification" : "event-notification",
              children: notificationLinks(tenantName, t),
            },
          ]
        : []),
    ],
  },
];

const documentationLinks = (t: TFunction) => {
  const envs = getEnvVarsStorage();

  const environmentName = getEnvironment(envs?.currentEnv);

  return getDocumentationMenu(
    t,
    envs?.hideInternationalPaymentsSection,
    envs?.tenantName,
    envs?.isCoreBankNeeded,
    environmentName,
  ).map(({ link, icon, name, children }) => {
    return (
      <Fragment key={name}>
        <ListItemButton sx={{ p: "8px 0" }} component={Link} to={link}>
          <ListItemIcon sx={{ pl: "27px", color: "common.black" }}>{icon}</ListItemIcon>
          <Typography sx={{ pr: "8px", color: "text.primary", fontWeight: 700 }}>{name}</Typography>
        </ListItemButton>

        {children.map(item => {
          return <SidebarChildrenItem key={item.link} {...item} />;
        })}
      </Fragment>
    );
  });
};

const DocumentationSidebar = () => {
  const { t } = useTranslation("docs");
  return <List sx={listWrapper}>{documentationLinks(t)}</List>;
};

export default DocumentationSidebar;
