import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

import { Button, Box, MenuItem, useTheme } from "@mui/material";
import { ArrowForwardIosRounded, KeyboardArrowDownRounded } from "@mui/icons-material";

import { UserAvatarWithMenu, LocaleSwitcher, MenuWithArrow } from "components/common/index";
import BaseHeader from "./BaseHeader";
import useMenuState from "hooks/useMenuState";
import { getEnvVarsStorage } from "helpers";
import { signInBtn, navBtn, nav } from "./styles";
import { AppRoutes, QueryKeys } from "types";

const docsLinks = (t: TFunction) => [
  { text: t("nav.documentation"), route: AppRoutes.DOCUMENTATION, hashText: "" },
  { text: t("nav.userGuides"), route: `${AppRoutes.DOCUMENTATION}#user-guide`, hashText: "#user-guide" },
  { text: t("nav.standards"), route: `${AppRoutes.DOCUMENTATION}#standards`, hashText: "#standards" },
  { text: t("nav.faqs"), route: `${AppRoutes.DOCUMENTATION}#faq`, hashText: "#faq" },
];

const NavButton: React.FC<{ to: AppRoutes; text: string }> = ({ to, text }) => {
  const theme = useTheme();
  return (
    <Button
      sx={navBtn}
      size="small"
      component={NavLink}
      to={to}
      // @ts-ignore
      style={({ isActive }) => ({ color: isActive ? theme.palette.primary.main : theme.palette.text.primary })}
    >
      {text}
    </Button>
  );
};

const Header: React.FC = () => {
  const { t } = useTranslation("common");
  const theme = useTheme();
  const { pathname, hash } = useLocation();

  const envs = getEnvVarsStorage();

  const client = useQueryClient();
  const user = client.getQueryData(QueryKeys.USER);

  const [anchorEl, handleClick, handleClose] = useMenuState();

  return (
    <BaseHeader>
      <Box component="nav" sx={nav}>
        <NavButton to={AppRoutes.PRODUCTS} text={t("nav.products")} />
        <Button
          sx={{ color: pathname.includes("docs") ? "primary" : "text.primary", ml: "10px" }}
          size="small"
          endIcon={<KeyboardArrowDownRounded />}
          onClick={handleClick}
        >
          {t("nav.developers")}
        </Button>
        <MenuWithArrow anchorEl={anchorEl} onClose={handleClose} onClick={handleClose}>
          {docsLinks(t).map(({ text, route, hashText }) => {
            return (
              <MenuItem
                key={text}
                component={NavLink}
                to={route}
                // @ts-ignore
                style={({ isActive }) => ({
                  color: isActive && hash === hashText ? theme.palette.primary.main : theme.palette.text.primary,
                })}
              >
                {text}
              </MenuItem>
            );
          })}
        </MenuWithArrow>

        <NavButton to={AppRoutes.CONTACT_US} text={t("nav.contact")} />
        {!!user && (
          <>
            <NavButton to={AppRoutes.DASHBOARD} text={t("nav.dashboard")} />
            <NavButton to={AppRoutes.ANALYTICS} text={t("nav.analytics")} />
          </>
        )}
      </Box>

      <div>
        <LocaleSwitcher />
        {!user && (
          <Button sx={signInBtn} size="small" href={envs?.authUrl}>
            {t("buttons.signIn")}
          </Button>
        )}
        {!user && (
          <Button
            sx={signInBtn}
            variant="outlined"
            size="small"
            endIcon={<ArrowForwardIosRounded />}
            component={Link}
            to={AppRoutes.REGISTER}
          >
            {t("buttons.getStarted")}
          </Button>
        )}
        {!!user && <UserAvatarWithMenu />}
      </div>
    </BaseHeader>
  );
};

export default Header;
