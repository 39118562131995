import { Badge, BadgeProps, badgeClasses, styled, lighten } from "@mui/material";

const CenteredRightBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  alignItems: "center",

  [`& .${badgeClasses.badge}`]: {
    position: "static",
    backgroundColor: lighten(theme.palette.common.black, 0.9),
    color: theme.palette.text.primary,
    marginLeft: "4px",
    transform: "none",
  },

  [`& .${badgeClasses.invisible}`]: {
    position: "absolute",
    transform: "scale(0)",
  },
}));

export default CenteredRightBadge;
