import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const Accounts2Icon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon width="31" height="32" viewBox="0 0 31 32" {...props}>
      <path d="M11.6712 14.5729C15.6954 14.5729 18.9577 11.3108 18.9577 7.28647C18.9577 3.2623 15.6954 0 11.6712 0C7.64707 0 4.38477 3.2623 4.38477 7.28647C4.38864 11.3093 7.64858 14.5691 11.6712 14.5729ZM11.6712 1.37867C14.9342 1.37867 17.579 4.02395 17.579 7.28647C17.579 10.5494 14.9342 13.1943 11.6712 13.1943C8.4085 13.1943 5.76365 10.5494 5.76365 7.28647C5.76732 4.02524 8.41001 1.38255 11.6712 1.37867Z" />
      <path d="M30.6075 18.6469L24.6653 15.4828C24.4605 15.3712 24.2127 15.3738 24.0103 15.4895L18.5713 18.4676C16.579 16.9854 14.1936 16.2134 11.6572 16.2134C8.49987 16.2203 5.57011 17.4475 3.39854 19.6809C1.19939 21.935 -0.00708083 25.0165 3.12654e-05 28.3599C0.00110884 28.7403 0.309085 29.0482 0.689259 29.0493L19.8672 29.0148C20.7687 30.1342 21.9277 31.0187 23.245 31.5931L24.0448 31.9446C24.1316 31.9834 24.2258 32.0021 24.3206 31.9998C24.415 31.9981 24.5086 31.9795 24.5963 31.9446L25.4993 31.5517C28.8172 30.1597 30.9752 26.912 30.9728 23.3137V19.2602C30.9731 19.0042 30.8328 18.7686 30.6075 18.6469ZM1.39939 27.6638C1.54401 24.9545 2.59875 22.4799 4.39122 20.6391C6.3005 18.6745 8.88564 17.5921 11.6639 17.5921H11.6846C13.9665 17.5921 16.1036 18.3092 17.8751 19.6671V23.3965C17.8762 24.8771 18.2456 26.334 18.9504 27.6359L1.39939 27.6638ZM29.5942 23.3137C29.5955 26.3603 27.7657 29.109 24.9547 30.2834H24.9478L24.3206 30.5521L23.7965 30.3245C21.0396 29.12 19.2568 26.3984 19.2538 23.3898V19.6602L24.348 16.8684L29.5942 19.6671V23.3137Z" />
      <path d="M22.4592 22.9488C22.2118 22.6596 21.7767 22.6257 21.4872 22.8731C21.198 23.1206 21.1642 23.5557 21.4116 23.8449L22.9763 25.6717C23.1077 25.8245 23.2989 25.9124 23.5002 25.913C23.6582 25.9143 23.8116 25.8609 23.9345 25.7613L27.5744 22.8042C27.8694 22.5624 27.9125 22.1272 27.6707 21.832C27.4291 21.5369 26.994 21.4938 26.6989 21.7356L23.583 24.2725L22.4592 22.9488Z" />
    </SvgIcon>
  );
};

export default Accounts2Icon;
