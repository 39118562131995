import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const UserGuideIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon width="16" height="17" viewBox="0 0 16 17" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 1.32324H1C0.45 1.32324 0 1.77324 0 2.32324V14.3232C0 14.8732 0.45 15.3232 1 15.3232H15C15.55 15.3232 16 14.8732 16 14.3232V2.32324C16 1.77324 15.55 1.32324 15 1.32324ZM3.5 7.32324H10.5C10.78 7.32324 11 7.10324 11 6.82324C11 6.54324 10.78 6.32324 10.5 6.32324H3.5C3.22 6.32324 3 6.54324 3 6.82324C3 7.10324 3.22 7.32324 3.5 7.32324ZM2 13.3232H14V5.32324H2V13.3232ZM3.5 9.32324H7.5C7.78 9.32324 8 9.10324 8 8.82324C8 8.54324 7.78 8.32324 7.5 8.32324H3.5C3.22 8.32324 3 8.54324 3 8.82324C3 9.10324 3.22 9.32324 3.5 9.32324ZM8.5 11.3232H3.5C3.22 11.3232 3 11.1032 3 10.8232C3 10.5432 3.22 10.3232 3.5 10.3232H8.5C8.78 10.3232 9 10.5432 9 10.8232C9 11.1032 8.78 11.3232 8.5 11.3232Z"
      />
    </SvgIcon>
  );
};

export default UserGuideIcon;
