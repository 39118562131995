import React from "react";
import { Outlet } from "react-router-dom";

import { Box } from "@mui/material";

import { Footer, FooterWithLinks, PolicyDialog, Header } from "components/common";
import { container } from "./styles";

const Layout: React.FC = () => {
  return (
    <Box>
      <Header />

      <Box component="main" sx={container}>
        <Outlet />
      </Box>

      <FooterWithLinks />
      <Footer />
      <PolicyDialog />
    </Box>
  );
};

export default Layout;
