import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const CostIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon width="48" height="48" viewBox="0 0 48 48" {...props}>
      <path d="M24 36C17.382 36 12 30.618 12 24C12 17.382 17.382 12 24 12C30.618 12 36 17.382 36 24C36 30.618 30.618 36 24 36ZM24 14C18.486 14 14 18.486 14 24C14 29.514 18.486 34 24 34C29.514 34 34 29.514 34 24C34 18.486 29.514 14 24 14Z" />
      <path d="M1.54 30C1.082 30 0.67 29.684 0.566 29.22C0.19 27.556 0 25.8 0 24C0 10.766 10.766 0 24 0C31.508 0 38.44 3.422 43.014 9.392C43.35 9.83 43.266 10.458 42.828 10.794C42.388 11.13 41.764 11.046 41.426 10.608C37.234 5.138 30.882 2 24 2C11.87 2 2 11.87 2 24C2 25.654 2.174 27.262 2.516 28.78C2.638 29.32 2.3 29.854 1.76 29.976C1.686 29.992 1.612 30 1.54 30Z" />
      <path d="M23.9993 48.0001C16.4913 48.0001 9.55933 44.5781 4.98533 38.6081C4.64933 38.1701 4.73333 37.5421 5.17133 37.2061C5.61133 36.8721 6.23733 36.9561 6.57333 37.3921C10.7653 42.8621 17.1173 46.0001 23.9993 46.0001C36.1293 46.0001 45.9993 36.1301 45.9993 24.0001C45.9993 22.3241 45.8213 20.7181 45.4673 19.2321C45.3393 18.6941 45.6713 18.1541 46.2093 18.0281C46.7513 17.8981 47.2873 18.2321 47.4133 18.7701C47.8013 20.4081 47.9993 22.1681 47.9993 24.0001C47.9993 37.2341 37.2333 48.0001 23.9993 48.0001Z" />
      <path d="M5.10003 48C4.55203 48 4.10603 47.558 4.10003 47.01L4.00003 37.01C3.99803 36.744 4.10203 36.486 4.29003 36.298C4.47803 36.106 4.73403 36 5.00003 36H15C15.552 36 16 36.448 16 37C16 37.552 15.552 38 15 38H6.01003L6.10003 46.99C6.10603 47.542 5.66403 47.994 5.11003 48C5.10803 48 5.10403 48 5.10003 48Z" />
      <path d="M43 12H33C32.448 12 32 11.552 32 11C32 10.448 32.448 10 33 10H42V1C42 0.448 42.448 0 43 0C43.552 0 44 0.448 44 1V11C44 11.552 43.552 12 43 12Z" />
      <path d="M21.9985 28.9998C21.7345 28.9998 21.4785 28.8938 21.2905 28.7078L17.7905 25.2078C17.4005 24.8178 17.4005 24.1838 17.7905 23.7938C18.1805 23.4038 18.8145 23.4038 19.2045 23.7938L21.9445 26.5338L27.7405 19.8458C28.1045 19.4298 28.7345 19.3838 29.1525 19.7458C29.5685 20.1078 29.6145 20.7398 29.2525 21.1578L22.7525 28.6578C22.5705 28.8658 22.3105 28.9918 22.0325 29.0018C22.0225 28.9998 22.0105 28.9998 21.9985 28.9998Z" />
    </SvgIcon>
  );
};

export default CostIcon;
