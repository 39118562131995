import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const FaqIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon width="16" height="17" viewBox="0 0 16 17" {...props}>
      <path d="M6 0.323242H15C15.55 0.323242 16 0.773242 16 1.32324V8.32324C16 8.87324 15.55 9.32324 15 9.32324V11.3232C15 11.8732 14.55 12.3232 14 12.3232C13.72 12.3232 13.47 12.2132 13.3 12.0332L10.59 9.32324H6C5.45 9.32324 5 8.87324 5 8.32324V1.32324C5 0.773242 5.45 0.323242 6 0.323242ZM4 8.32324C4 9.42324 4.9 10.3232 6 10.3232H10.17L11 11.1532V12.3232C11 12.8732 10.55 13.3232 10 13.3232H5.41L2.71 16.0332C2.53 16.2132 2.28 16.3232 2 16.3232C1.45 16.3232 1 15.8732 1 15.3232V13.3232C0.45 13.3232 0 12.8732 0 12.3232V4.32324C0 3.77324 0.45 3.32324 1 3.32324H4V8.32324Z" />
    </SvgIcon>
  );
};

export default FaqIcon;
