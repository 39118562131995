import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useQueryClient } from "react-query";

import { AppRoutes, QueryKeys } from "types";

const ProtectedRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
  let location = useLocation();
  const client = useQueryClient();
  const user = client.getQueryData(QueryKeys.USER);

  if (!user) {
    return <Navigate to={AppRoutes.HOME} state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoute;
