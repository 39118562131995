import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { format } from "date-fns";

import { alpha, Box, Container, Stack, styled, Typography, Link as MUILink } from "@mui/material";

import { copyrightWrapper, flexContainer } from "./styles";
import { AppRoutes } from "types";

const FooterWrapper = styled("footer")(({ theme }) => ({
  borderTop: `1px solid ${alpha(theme.palette.common.black, 0.1)}`,
  backgroundColor: theme.palette.common.white,
}));

interface FooterProps {
  isAdditionalLinks?: boolean;
}

const Footer: React.FC<FooterProps> = ({ isAdditionalLinks = false }) => {
  const { t } = useTranslation("common");

  return (
    <FooterWrapper>
      <Box sx={copyrightWrapper}>
        <Container sx={flexContainer}>
          <Typography color="text.primary">
            &copy; {format(new Date(), "yyyy")}. {t("footer.copyright")}
          </Typography>

          {isAdditionalLinks && (
            <Stack direction={{ xs: "column", sm: "row" }}>
              <MUILink component={Link} to={AppRoutes.TERMS} underline="none" color="text.primary" sx={{ mr: "24px" }}>
                {t("nav.termsConditions")}
              </MUILink>
              <MUILink component={Link} to={AppRoutes.PRIVACY} underline="none" color="text.primary">
                {t("nav.privacyPolicy")}
              </MUILink>
            </Stack>
          )}
        </Container>
      </Box>
    </FooterWrapper>
  );
};

export default Footer;
