import { SystemStyleObject } from "@mui/system/styleFunctionSx/styleFunctionSx";
import { Theme } from "@mui/material";

export const sidebar: SystemStyleObject<Theme> = {
  width: 240,
  height: "fit-content",
  padding: "24px",
  backgroundColor: "background.default",
};

export const formWrapper: SystemStyleObject<Theme> = {
  maxWidth: "552px",
  width: "100%",
};
