import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const PaymentsIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon width="72" height="62" viewBox="0 0 72 62" {...props}>
      <path d="M0.123047 7.45786C0.123047 3.3708 3.37539 0 7.45764 0H59.2312C63.3136 0 66.5659 3.3708 66.5659 7.45786V23.7296C66.5659 24.6031 65.8575 25.3116 64.9839 25.3116C64.1103 25.3116 63.4019 24.6031 63.4019 23.7296V7.45786C63.4019 5.05462 61.5032 3.16394 59.2312 3.16394H7.45764C5.1857 3.16394 3.28699 5.05462 3.28699 7.45786V36.8375C3.28699 39.2405 5.1857 41.1313 7.45764 41.1313H30.1805C31.0541 41.1313 31.7625 41.8397 31.7625 42.7132C31.7625 43.5868 31.0541 44.2952 30.1805 44.2952H7.45764C3.37539 44.2952 0.123047 40.9244 0.123047 36.8375V7.45786Z" />
      <path d="M0.123047 13.2241C0.123047 12.3504 0.831321 11.6421 1.70502 11.6421H64.9839C65.8575 11.6421 66.5659 12.3504 66.5659 13.2241C66.5659 14.0978 65.8575 14.806 64.9839 14.806H1.70502C0.831321 14.806 0.123047 14.0978 0.123047 13.2241Z" />
      <path d="M6.45117 27.016C6.45117 26.1425 7.15945 25.4341 8.03314 25.4341H14.361C15.2347 25.4341 15.943 26.1425 15.943 27.016C15.943 27.8896 15.2347 28.598 14.361 28.598H8.03314C7.15945 28.598 6.45117 27.8896 6.45117 27.016Z" />
      <path d="M6.45117 34.9257C6.45117 34.0522 7.15945 33.3438 8.03314 33.3438H20.6889C21.5626 33.3438 22.2709 34.0522 22.2709 34.9257C22.2709 35.7993 21.5626 36.5077 20.6889 36.5077H8.03314C7.15945 36.5077 6.45117 35.7993 6.45117 34.9257Z" />
      <path d="M54.4778 29.7333C46.6145 29.7333 40.2401 36.1077 40.2401 43.971C40.2401 51.8344 46.6145 58.2087 54.4778 58.2087C62.3412 58.2087 68.7156 51.8344 68.7156 43.971C68.7156 36.1077 62.3412 29.7333 54.4778 29.7333ZM37.0762 43.971C37.0762 34.3602 44.8671 26.5693 54.4778 26.5693C64.0886 26.5693 71.8795 34.3602 71.8795 43.971C71.8795 53.5818 64.0886 61.3727 54.4778 61.3727C44.8671 61.3727 37.0762 53.5818 37.0762 43.971Z" />
      <path d="M59.6893 41.2703C60.3072 40.6527 61.3089 40.6527 61.9269 41.2703C62.5445 41.8883 62.5445 42.89 61.9269 43.5079L55.599 49.8358C54.9811 50.4534 53.9794 50.4534 53.3615 49.8358L48.6155 45.0898C47.9979 44.4719 47.9979 43.4702 48.6155 42.8523C49.2335 42.2347 50.2352 42.2347 50.8531 42.8523L54.4802 46.4798L59.6893 41.2703Z" />
    </SvgIcon>
  );
};

export default PaymentsIcon;
