import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import { List, ListItemButton, useTheme } from "@mui/material";

import { sidebar } from "./styles";
import { AppRoutes } from "types";

const SideBar: React.FC = () => {
  const { t } = useTranslation(["common", "profile"]);
  const theme = useTheme();

  return (
    <List sx={sidebar}>
      <ListItemButton
        component={NavLink}
        to={AppRoutes.ACCOUNT_SETTINGS}
        // @ts-ignore
        style={({ isActive }) => ({
          color: isActive ? theme.palette.text.primary : theme.palette.text.secondary,
          fontWeight: isActive ? 600 : 400,
          padding: "5px",
        })}
      >
        {t("sideBar.accountSettings", { ns: "profile" })}
      </ListItemButton>
      <ListItemButton
        component={NavLink}
        to={AppRoutes.RESET_PASSWORD}
        // @ts-ignore
        style={({ isActive }) => ({
          color: isActive ? theme.palette.text.primary : theme.palette.text.secondary,
          fontWeight: isActive ? 600 : 400,
          padding: "5px",
        })}
      >
        {t("sideBar.password", { ns: "profile" })}
      </ListItemButton>
    </List>
  );
};

export default SideBar;
