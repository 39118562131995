import React from "react";
import { Box, CircularProgress } from "@mui/material";
import { FOOTER_HEIGHT, HEADER_HEIGHT } from "../../constants/globals";

interface PageLoadingSpinnerProps {
  blockHeight?: React.CSSProperties["height"];
  blockWidth?: React.CSSProperties["width"];
}

const PageLoadingSpinner: React.FC<PageLoadingSpinnerProps> = ({ blockHeight = "100vh", blockWidth = "100vw" }) => {
  return (
    <Box
      sx={{
        // height: blockHeight,
        width: blockWidth,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: `calc(100vh - ${HEADER_HEIGHT + FOOTER_HEIGHT}px)`,
      }}
    >
      <CircularProgress size={60} />
    </Box>
  );
};

export default PageLoadingSpinner;
