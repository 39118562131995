import { AxiosError } from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { useGetAxiosRequests } from "config/axiosConfig";
import { getSession } from "helpers/sessionToken";
import { TUpdateUserFormValues, IUserInfo, QueryKeys, IPasswordResetFormValues } from "types";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "helpers";

export const useGetUserDataQuery = () => {
  const { getCurrentUserData } = useGetAxiosRequests();

  return useQuery<IUserInfo, AxiosError<any>>(QueryKeys.USER, getCurrentUserData, {
    enabled: !!getSession()?.access_token,
  });
};

export const useUpdateUserMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { updateUser } = useGetAxiosRequests();
  const queryClient = useQueryClient();

  return useMutation<unknown, AxiosError<any>, TUpdateUserFormValues>(updateUser, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(QueryKeys.USER);
      enqueueSnackbar("Updated your Profile", { variant: "success" });
    },
    onError: error => {
      enqueueSnackbar(error.response?.data.message ?? error.message, { variant: "error" });
    },
  });
};

export const useChangePasswordMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { changePassword } = useGetAxiosRequests();

  return useMutation<unknown, AxiosError<any>, IPasswordResetFormValues>(changePassword, {
    onSuccess: () => {
      enqueueSnackbar("Password changed successfully", { variant: "success" });
    },
    onError: error => {
      enqueueSnackbar(getErrorMessage(error), { variant: "error" });
      console.error("change password error:", error.message);
    },
  });
};
