import React, { LegacyRef } from "react";
import Captcha from "react-google-recaptcha";

import { getEnvVarsStorage } from "helpers";

const Recaptcha = React.forwardRef((props, ref) => {
  const envs = getEnvVarsStorage();

  return envs?.recaptchaEnabled ? (
    <Captcha ref={ref as LegacyRef<Captcha> | undefined} sitekey={envs?.recaptchaSiteKey} {...props} />
  ) : null;
});

export default Recaptcha;
