import React from "react";
import { useTranslation } from "react-i18next";

import { Button, Typography } from "@mui/material";
import { ArrowForwardIosRounded } from "@mui/icons-material";

import { LocaleSwitcher } from "components/common/index";
import BaseHeader from "./BaseHeader";
import { getEnvVarsStorage } from "helpers";
import { signInBtn } from "./styles";

const Header: React.FC = () => {
  const { t } = useTranslation(["common", "auth"]);

  const envs = getEnvVarsStorage();

  return (
    <BaseHeader>
      <div>
        <LocaleSwitcher />
        <Typography component="span" color="text.primary" sx={{ mx: "7px" }}>
          {t("alreadyHave", { ns: "auth" })}
        </Typography>
        <Button
          sx={signInBtn}
          variant="outlined"
          size="small"
          endIcon={<ArrowForwardIosRounded />}
          href={envs?.authUrl}
        >
          {t("buttons.signIn")}
        </Button>
      </div>
    </BaseHeader>
  );
};

export default Header;
