import React from "react";

import { MenuItem, Select, Stack, Theme, Typography } from "@mui/material";
import { SystemStyleObject } from "@mui/system/styleFunctionSx/styleFunctionSx";
import { KeyboardArrowDownRounded } from "@mui/icons-material";

import TablePagination from "./TablePagination";
import { ROWS_IN_TABLE } from "constants/globals";

export const selectWrapper: SystemStyleObject<Theme> = {
  "& .MuiSelect-outlined": {
    padding: "4px 0 4px 12px",
    fontWeight: 600,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
  "& svg": {
    fill: theme => theme.palette.grey[500],
  },
};
export const showSelect: SystemStyleObject<Theme> = {
  color: theme => theme.palette.grey[500],
};

interface IProps {
  total: number;
  limit: number;
  page: number;
  setLimit: (v: number) => void;
  setPage: (v: number) => void;
}

const LimitAndPagination: React.FC<IProps> = ({ total, limit, page, setLimit, setPage }) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <TablePagination
        count={Math.ceil((total ?? 0) / (limit ?? 1))}
        page={page}
        onChange={(e, value) => setPage(value)}
      />

      <Stack direction="row" alignItems="center">
        <Typography sx={showSelect}>Per page</Typography>
        <Select
          value={limit}
          sx={selectWrapper}
          IconComponent={KeyboardArrowDownRounded}
          onChange={e => setLimit(e.target.value as number)}
        >
          {ROWS_IN_TABLE.map(v => (
            <MenuItem key={v} value={v}>
              {v}
            </MenuItem>
          ))}
        </Select>
      </Stack>
    </Stack>
  );
};

export default LimitAndPagination;
