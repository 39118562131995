import { useTranslation } from "react-i18next";

import { Avatar, IconButton, MenuItem } from "@mui/material";

import MenuWithArrow from "./MenuWithArrow";
import useMenuState from "hooks/useMenuState";
import useHandleLanguage from "hooks/useHandleLanguage";
import { LOCALES_MAP, isSbi } from "constants/globals";
import { LocaleTypes } from "types";
import { getEnvVarsStorage } from "helpers";

const LocaleSwitcher = () => {
  const { i18n } = useTranslation();
  const [anchorEl, handleClick, handleClose] = useMenuState();

  const [envLocales, langChange] = useHandleLanguage();
  const envs = getEnvVarsStorage();

  return (
    <>
      {envLocales.length > 1 ? (
        <IconButton onClick={handleClick} size="small" sx={{ mx: "7px" }}>
          <Avatar
            sx={{ width: 24, height: 24 }}
            src={`/images/flags/Country=${LOCALES_MAP[i18n.language as LocaleTypes].flag}.svg`}
          />
        </IconButton>
      ): null}
      <MenuWithArrow anchorEl={anchorEl} onClose={handleClose} onClick={handleClose}>
        {envLocales.map(locale => {
          return (
            <MenuItem key={locale} onClick={() => langChange(locale)}>
              <Avatar
                sx={{ width: 24, height: 24, mr: "10px" }}
                src={`/images/flags/Country=${LOCALES_MAP[locale].flag}.svg`}
              />
              {LOCALES_MAP[locale].text}
            </MenuItem>
          );
        })}
      </MenuWithArrow>
    </>
  );
};

export default LocaleSwitcher;
