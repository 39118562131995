import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";

import { useSnackbar } from "notistack";

import { useGetAxiosRequests } from "config/axiosConfig";
import { removeSession, setSession } from "helpers/sessionToken";
import { AppRoutes, IRegisterFormValues, QueryKeys, TokenExchangeData } from "types";

export const useRegisterMutation = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { signUp } = useGetAxiosRequests();

  return useMutation<undefined, AxiosError<any>, IRegisterFormValues>(signUp, {
    onSuccess: () => {
      enqueueSnackbar("Thank you for signing up", { variant: "success" });
      navigate(AppRoutes.HOME, { replace: true });
    },
    onError: error => {
      enqueueSnackbar(error.response?.data.message ?? error.message, { variant: "error" });
    },
  });
};

export const useExchangeMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const client = useQueryClient();
  const { exchange, getCurrentUserData } = useGetAxiosRequests();

  return useMutation<TokenExchangeData, AxiosError<any>, string>(exchange, {
    onSuccess: async ({ id_token, refresh_token, access_token }) => {
      setSession({
        id_token,
        access_token,
        refresh_token,
        timestamp: new Date().getTime(),
      });
      await client.prefetchQuery(QueryKeys.USER, getCurrentUserData);
      await navigate(AppRoutes.DASHBOARD);
    },
    onError: error => {
      enqueueSnackbar(`Token request Error: ${error.message}`, { variant: "error" });
      navigate(AppRoutes.HOME);
    },
  });
};

export const useLogOutMutation = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { logOut } = useGetAxiosRequests();

  return useMutation(logOut, {
    onSuccess: async () => {
      removeSession();
      queryClient.clear();
      await queryClient.setQueryData(QueryKeys.USER, () => undefined);
      await navigate(AppRoutes.LOGIN, { replace: true });
    },
    onError: error => {
      console.error("* logout error:", error);
    },
  });
};
