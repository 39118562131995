import React from "react";
import { IconButton } from "@mui/material";
import { CheckBoxRounded, ContentCopyRounded } from "@mui/icons-material";
import { SetFutureDate } from "components/layouts/configs";
import { getEnvVarsStorage } from "helpers";

const CodeCopyBtn = ({ children }: any) => {
  const [copyOk, setCopyOk] = React.useState(false);
  const envs = getEnvVarsStorage();

  const handleClick = () => {
    navigator.clipboard.writeText(SetFutureDate(children[0].props.children[0].trim(), envs));

    setCopyOk(true);
    setTimeout(() => setCopyOk(false), 1000);
  };

  return (
    <IconButton sx={{ position: "absolute", right: 5, top: 5 }} size="small" onClick={handleClick}>
      {copyOk ? <CheckBoxRounded color="success" /> : <ContentCopyRounded color="action" />}
    </IconButton>
  );
};

export default CodeCopyBtn;
