import { Buffer } from "buffer";

import { defaultProductData } from "constants/globals";

export const randomNum = (n: number) => {
  return Math.floor(Math.pow(10, n) + Math.random() * 9 * Math.pow(10, n - 1));
};

export const generateRandomString = (length: number) => {
  var text = "";

  var charset = "abcdefghijklmnopqrstuvwxyz";

  for (var i = 0; i < length; i++) text += charset.charAt(Math.floor(Math.random() * charset.length));

  return text;
};

export const objectToQueryString = (object: Record<string, any>) => {
  let newSearchParams = new URLSearchParams(object);
  return `?${newSearchParams.toString()}`;
};

export const jwtDecode = (token: string): Record<string, any> => {
  return JSON.parse(Buffer.from(token.split(".")[1], "base64").toString());
};

export const getEnvVarsStorage = (): Record<string, any> | null => {
  if (typeof window !== "undefined") {
    const session = localStorage.getItem("env");
    if (session) {
      return JSON.parse(session);
    }
  }
  return null;
};

export const setEnvVarsStorage = (data: Record<string, any>) => {
  if (typeof window !== "undefined") {
    return localStorage.setItem("env", JSON.stringify(data));
  }
  return null;
};

export const getProducts = () => {
  const envs = getEnvVarsStorage();

  return defaultProductData.filter(({ title, scopes }) =>
    envs?.tabsProducts.some((p: string) => title.toLowerCase().includes(p) || scopes.includes(p)),
  );
};

export const getScope = ({ title }: { title: string }) => {
  if (title.includes("AISP")) return "accounts";
  if (title.includes("PISP")) return "payments";
  if (title.includes("CBPII")) return "fundsconfirmations";
  if (title.includes("NOTIFICATION")) return "events";
  return null;
};

export const getErrorMessage = (error: any): string => {
  return (
    error?.response?.data?.errors?.[0]?.msg ||
    error?.response?.data?.message ||
    error?.message ||
    (typeof error === "string" ? error : "System error occurred, please try again later")
  );
};

export const futureDateFn =(months: number) => {
  let futureDate = new Date();
  futureDate.setMonth(futureDate.getMonth() + months);
  let result = futureDate.toISOString()
  return result;
};