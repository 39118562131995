import axios, { AxiosInstance } from "axios";

import { randomNum } from "helpers";
import { IAxiosBaseContext } from "types";
import { locales } from "constants/globals";

export const axiosRequests = (instance: AxiosInstance) => {
  return {
    signUp: async ({ email, ...rest }) => {
      const { data } = await instance.post(`/register`, { ...rest, username: email });
      return data;
    },
    exchange: async code => {
      const { data } = await instance.post("/exchange", { code });
      return data;
    },
    refreshToken: async refresh_token => {
      const { data } = await instance.post("/refresh", { refresh_token });
      return data;
    },
    logOut: async tokens => {
      const { data } = await instance.post("/logout", tokens);
      return data;
    },
    cibaAuth: async values => {
      const { data } = await instance.post("/ciba-token", values);
      return data;
    },
    cibaCallback: async accessToken => {
      const { data } = await instance.post("/ciba-callback", { accessToken });
      return data;
    },
    cibaExchange: async values => {
      const { data } = await instance.post("/ciba-exchange", values);
      return data;
    },
    cibaAccounts: async values => {
      const { data } = await instance.post("/ciba-accounts", values);
      // setSession({ ...data.token, timestamp: new Date().getTime() });

      return data;
    },
    cibaConfirmAccounts: async values => {
      const { data } = await instance.post("/ciba-confirm-accounts", values);
      return data;
    },
    cibaDenyAccounts: async values => {
      const { data } = await instance.post("/ciba-deny-accounts", values);
      return data;
    },
    contactUsRequest: async values => {
      const { data } = await instance.post("/contact", values);
      return data;
    },
    getCurrentUserData: async () => {
      const { data } = await instance.get(`/session`);
      return data;
    },
    updateUser: async values => {
      const { data } = await instance.put("/session", values);
      return data;
    },
    changePassword: async values => {
      const { data } = await instance.put("/password", values);
      return data;
    },

    getApps: async query => {
      const { data } = await instance.get("/apps" + (query ? query : ""));
      return data;
    },
    getApp: async context => {
      const clientId = context.queryKey[1];
      const { data } = await instance.get(`/apps/${clientId}`);
      return data;
    },
    createApp: async body => {
      const { data } = await instance.post("/apps", body);
      return data;
    },
    updateApp: async ({ clientId, ...rest }) => {
      const { data } = await instance.put(`/apps/${clientId}`, rest);
      return data;
    },
    deleteApp: async id => {
      const { data } = await instance.delete(`/apps/${id}`);
      return data;
    },
    getProducts: async () => {
      const { data } = await instance.get("/products");
      return data;
    },
    getConsentBasedOnId: async context => {
      const consentId = context.queryKey[1];
      const scopeType = context.queryKey[2];
      const consentType = context.queryKey[3];

      // console.log("getConsentBasedOnId>>", consentId, scopeType, consentType);

      const { data } = await instance.get(
        `/consent?consentId=${consentId}&scope=${scopeType}&consentType=${consentType}`,
      );
      return data;
    },
    getSwaggerSpec: async context => {
      const serviceId = context.queryKey[1];
      const { data } = await instance.get(`/products/${serviceId}/swagger.json`);
      return data;
    },
    getAnalyticsUsage: async context => {
      const params = context.queryKey[1];
      const usage = context.queryKey[2];

      const { data } = await instance.get(
        `/analytics/usage/${params}&analyticsIds=${JSON.stringify(usage.map(app => !!app && app.clientId))}`,
      );
      return data;
    },
  } as IAxiosBaseContext;
};

// export const getSwaggerSignToken = async (body: {
//   privateKey: string;
//   clientId: string;
//   requestId: string;
//   type: string;
//   authHost?: string;
//   redirectUri: string;
//   defaultScopes: string[];
//   tenantName?: string;
// }) => {
//   const { data } = await axios.post(`/token`, body);
//   return data;
// };

export const getEnvVars = async () => {
  const { data } = await axios.get(`/env-vars`);
  return {
    ...data,
    defaultScopes: ["openid", "profile", "email"],
    redirectUri: `${data.hostUrl}/login`,
    oauth2RedirectUrl: `${data.hostUrl}/login`,
    authUrl:
      `${data.authHost}/protocol/openid-connect/auth` +
      `?nonce=${randomNum(7)}` +
      `&client_id=${data.clientId}` +
      `&response_type=${data.authResponseType}` +
      `&redirect_uri=${data.hostUrl}/login` +
      `&scope=${data.authScope}` +
      `&ui_locales=${localStorage.getItem("lang")}`,
  };
};
