import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const ProductsIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon width="16" height="17" viewBox="0 0 16 17" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 0.323242H12C12.55 0.323242 13 0.773242 13 1.32324H3C3 0.773242 3.45 0.323242 4 0.323242ZM13 2.32324C13.55 2.32324 14 2.77324 14 3.32324V4.32324H2V3.32324C2 2.77324 2.45 2.32324 3 2.32324H13ZM15 5.32324H1C0.45 5.32324 0 5.77324 0 6.32324V15.3232C0 15.8732 0.45 16.3232 1 16.3232H15C15.55 16.3232 16 15.8732 16 15.3232V6.32324C16 5.77324 15.55 5.32324 15 5.32324ZM11 12.3232C11.55 12.3232 12 11.8732 12 11.3232V9.32324H11V11.3232H5V9.32324H4V11.3232C4 11.8732 4.45 12.3232 5 12.3232H11Z"
      />
    </SvgIcon>
  );
};

export default ProductsIcon;
