import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const MessageIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon width="42" height="32" viewBox="0 0 42 32" {...props}>
      <path d="M37.6666 1H4.33336C2.49492 1 1 2.49492 1 4.33336V27.6667C1 29.5051 2.49492 31 4.33336 31H37.6667C39.5051 31 41 29.5051 41 27.6666V4.33336C41 2.49492 39.5051 1 37.6666 1ZM4.33336 2.66664H37.6667C37.7895 2.66664 37.8977 2.71141 38.013 2.73672C35.1271 5.37789 25.558 14.132 22.2094 17.1491C21.9473 17.3851 21.525 17.6666 21.0001 17.6666C20.4752 17.6666 20.0528 17.3851 19.7899 17.1483C16.4416 14.1316 6.87195 5.37711 3.98656 2.73688C4.10203 2.71156 4.21047 2.66664 4.33336 2.66664ZM2.66664 27.6666V4.33336C2.66664 4.17008 2.71586 4.02195 2.75992 3.87273C4.96867 5.8943 11.6455 12.0024 15.9749 15.9394C11.6596 19.6462 4.98102 25.978 2.75469 28.101C2.71539 27.9593 2.66664 27.8207 2.66664 27.6666ZM37.6666 29.3334H4.33336C4.20023 29.3334 4.08187 29.287 3.95758 29.2573C6.25812 27.0641 12.9792 20.6959 17.2187 17.0691C17.7713 17.5704 18.2762 18.0275 18.6741 18.3861C19.361 19.0062 20.165 19.3334 21 19.3334C21.835 19.3334 22.639 19.0062 23.325 18.3869C23.7231 18.0281 24.2284 17.5706 24.7813 17.0691C29.021 20.6955 35.7412 27.0633 38.0424 29.2573C37.9181 29.287 37.7999 29.3334 37.6666 29.3334ZM39.3334 27.6666C39.3334 27.8206 39.2846 27.9593 39.2454 28.101C37.0182 25.977 30.3404 19.6458 26.0252 15.9395C30.3547 12.0025 37.0305 5.89492 39.2401 3.87258C39.2841 4.0218 39.3334 4.17 39.3334 4.33328V27.6666Z" />
    </SvgIcon>
  );
};

export default MessageIcon;
